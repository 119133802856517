import React, { useEffect, useRef } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import { InputLabel,MenuItem,Select} from "@mui/material"
// import FormControl from "@mui/material/FormControl";
// import { InputLabel,Select,MenuItem} from "@mui/material"
// import { MenuItem } from "@material-ui/core";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import { MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import "./footerPage.scss";
import { getCountryCode } from "../../util/Helper.js";
import axios from "axios";
import config from "../../config.js";
import {fetchCountrySettingsSuccess} from "../../redux";
import store from "../../redux/store";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    }
  },
  checkedIcon: {
    backgroundColor: "#10384f",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    }
  }
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
const DropDownFilters = props => {
  const { title, countrySettings } = { ...props };
  const [countryFilter, setcountryFilter] = React.useState(localStorage.getItem("LegalTextCountryFilter") !== undefined ?localStorage.getItem("LegalTextCountryFilter") :"" );
  const [countryLangData, setcountryLangData] = React.useState("");
  const [countryFilterData, setcountryFilterData] = React.useState("");
  const [countryLangFilter, setcountryLangFilter] = React.useState(localStorage.getItem("LegalTextLanguageFilter")?localStorage.getItem("LegalTextLanguageFilter") :"");
  const [userDefaultLang, setUserDefaultLang] = React.useState("");
  const userId = localStorage.email;
  let langValue;
  useEffect(() => {
    document.title = title;
  }, [title]);
  //const isTracking = getCookie("cookieconsent_status");
  /* var GA = "";
  if (isTracking === "allow") {
    GA = "on";
  } else {
    GA = "off";
  } */
  useEffect(() => {
    document.addEventListener("cookieOpted", () => {
      setTracking(getCookie("cookieconsent_status"));
    });
  });
  const [value, setValue] = React.useState(null);
  const [isTracking, setTracking] = React.useState(
    getCookie("cookieconsent_status")
  );
  const upperDivisionRef = useRef(null);
  const lowerDivisionRef = useRef(null);
  const getCountryLang = async (e) => {
    
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/config/getlegaldata/${e}`,
    });
    let afterLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.langCode === userDefaultLang)[0]?.lang
    let beforeLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.countryWithLangCode === countrySettings?.locale)[0]?.lang
  
   if (localStorage.getItem("LoggedIn") === 'false'){
//eslint-disable-next-line 
    langValue = beforeLogLang
   }else{
    langValue =afterLogLang
   }

    let filterResponse = response.data.data.filter((item)=> item.PK === countryFilter  && (item.SK ===(countryLangFilter ? countryLangFilter  : langValue? langValue :localStorage.getItem("LegalTextLanguageFilter")  )) )
    setcountryLangData(response.data.data)
    setcountryFilterData(filterResponse)
    return response
}

const userSettingsApi = async () => {
    
  const response = await axios({
    method: "get",
    url: `${config.favApi}dc/userdata/${userId}/settings`,
  });
  
  setUserDefaultLang(response.data.data.settings.defaultLang)
  return response
}


// const updateCountryLang = async (e) => {
//   const response = await axios({
//     method: "post",
//     url: `${config.baseApi}dc/config/addorupdatelegaldata`,
//     data:
//     {
//       "cookieConsent": "Cookie Consent",
//       "imprintHTMLText":` <div class="content-area-inner">
          
//       <div class="field field--name-field-content field--type-entity-reference-revisions field--label-hidden field__items">
//               <div class="field__item">
//   <div class="default-schema no-image paragraph paragraph--type--jumbotron paragraph--view-mode--default">
                  
// <div  class="content-wrapper">
      
// <h1  class="heading_h1_h2">
      
//             <div class="field field--name-field-title field--type-string field--label-hidden field__item">Imprint</div>
      
//   </h1>

//   </div>

//             </div>
// </div>
//               <div class="field__item">  <div id="92101" data-anchor-link-pid="pid-d8197d2fbc7236c9fdf25ba7091307d8" class="paragraph paragraph--type--html-editor paragraph--view-mode--default">
          
//             <div class="text-formatted field field--name-field-html-editor field--type-text-long field--label-hidden field__item"><p><strong>Published by:</strong></p>

// <p>&nbsp;</p>

// <p>Bayer B.V.</p>

// <p>Represented by Werner De Prins,<br>
// Senior Bayer Representative Benelux</p>

// <p>Sirius Dreef 36<br>
// 2132 WT Hoofddorp<br>
// The Netherlands</p>

// <p>+31 (0)23 799 1000<br>
// <a href="https://service.bayer.nl/?p=/nl/contactformulier-bayer-in-nederland">Contact form</a></p>

// <p>&nbsp;</p>

// <p>Chamber of Commerce: 30048949<br>
// VAT: NL 001494193B01</p>
// </div>
      
//       </div>
// </div>
//           </div>
  
//         </div>`,
//       "SK": "English",
//       "privacyLowerHTML":"",
//       "privacyUpperHTML":` <div class="content-area-inner">
//                                                             <div class="field field--name-field-content field--type-entity-reference-revisions field--label-hidden field__items">
//                                                                 <div class="field__item">
//                                                                     <div class="default-schema no-image paragraph paragraph--type--jumbotron paragraph--view-mode--default">
//                                                                         <div class="content-wrapper">
//                                                                             <h1 class="heading_h1_h2">
//                                                                                 <div class="field field--name-field-title field--type-string field--label-hidden field__item">Privacy Statement</div>
//                                                                             </h1>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div class="field__item">
//                                                                     <div id="92226" data-anchor-link-pid="pid-e671c311bcf629b57262907ba9f1eba1" class="paragraph paragraph--type--html-editor paragraph--view-mode--default">
//                                                                         <div class="text-formatted field field--name-field-html-editor field--type-text-long field--label-hidden field__item">
//                                                                             <p>
//                                                                                 This website (hereinafter the “Website“) is provided by Bayer AG (hereinafter “us” or “we”). For further information regarding the provider of the Website, please refer to our &nbsp;<a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="85d2792b-bfaa-45a6-9708-4c5e31096aeb" href="/en/imprint" title="Imprint">imprint</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 Information on other selected non-Website related data processing activities performed by us and all of our affiliates in Germany (e.g. when you give us a business card or we collect your data from publicly available sources) can be found in the &nbsp;<a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="24453fd0-2307-49f8-9c21-1105dac467e1" href="/en/corporate-compliance/data-privacy-statement-for-specific-processing-activities" title="data privacy information for specific processing activities">privacy information for selected specific processing activities.</a>
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>A. Handling of personal data</h2>
//                                                                             <p>In the following we wish to provide you with information on how we handle your personal data when you use our Website. Unless otherwise indicated in the following chapters, the legal basis for the handling of your personal data results from the fact that such handling is required to make available the functionalities of the Website requested by you (Art. 6(1)(b) General Data Protection Regulation).</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>I. Using our Website</h5>
//                                                                             <p>
//                                                                                 <strong>1. Accessing our Website</strong>
//                                                                             </p>
//                                                                             <p>When you call up our Website, your browser will transfer certain data to our web server. This is done for technical reasons and required to make available to you the requested information. To facilitate your access to the Website, the following data are collected, briefly stored and used:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>IP address</li>
//                                                                                 <li>Date and time of access</li>
//                                                                                 <li>Time zone difference to Greenwich Mean Time (GMT)</li>
//                                                                                 <li>Content of request (specific site)</li>
//                                                                                 <li>Status of access/HTTP status code</li>
//                                                                                 <li>Transferred volume of data</li>
//                                                                                 <li>Website requesting access</li>
//                                                                                 <li>Browser, language settings, version of browser software operating system and surface</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>Moreover, to protect our legitimate interests, we will store such data for a limited period of time in order to be able to initiate a tracking of personal data in the event of actual or attempted unauthorized access to our servers (Art. 6(1)(f) General Data Protection Regulation).</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>2. Setting of cookies</strong>
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>a) What are cookies?</h5>
//                                                                             <p>This Website uses so-called “cookies”. Cookies are small text files that are stored in the memory of your terminal via your browser. They store certain information (e.g. your preferred language or site settings) which your browser may (depending on the lifespan of the cookie) retransmit to us upon your next visit to our Website.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>b) What cookies do we use?</h5>
//                                                                             <p>
//                                                                                 We differentiate between two categories of cookies: (1) functional cookies, without which the functionality of our Website would be reduced, and (2) optional cookies used for e.g. website analysis and marketing purposes. The optional Cookie settings can be seen and changed in the <a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="49046f12-13ef-4215-9d4f-b1d6a867fe60" href="/en/privacy-statement" title="Privacy Statement">Global Privacy Statement</a>
//                                                                                 .&nbsp;
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>c) Subject to your consent</h5>
//                                                                             <p>We only use optional cookies if we have obtained your prior consent (Art. 6(1)(a) General Data Protection Regulation). Upon your first access to our Website, a banner will appear, asking you to give us your consent to the setting of optional cookies. If your consent is given, we will place a cookie on your computer and the banner will not appear again as long as the cookie is active. After expiration of the cookie’s lifespan, or if you actively delete the cookie, the banner will reappear upon your next visit to our Website and again ask for your consent.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>d) How to prevent the setting of cookies</h5>
//                                                                             <p>
//                                                                                 Of course you may use our Website without any cookies being set. In your browser, you can at any time configure or completely deactivate the use of cookies. This may, however, lead to a restriction of the functions or have adverse effects on the user-friendliness of our Website. You may at any time object to the setting of optional cookies by using the respective objection option indicated in the &nbsp;<a href="/node/1976#settings">table above</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>3. Website Analysis &nbsp;&nbsp;and Online Behavioral Advertising</strong>
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>Google Analytics</h5>
//                                                                             <p>On our Website we use Google Analytics, a web analysis service of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 Google will analyze your use of our Website on our behalf. To this purpose we use, among others, the cookies described in more detail in the above table. The information collected by Google in connection with your use of our Website (e.g. the referring URL, our webpages visited by you, your browser type, your language settings, your operating system, your screen resolution) will be transmitted to a server of Google in the US, where it will be stored and analyzed. The respective results will then be made available to us in anonymized form. Your usage data will not be connected to your full IP address during this process. We have activated on our Website the IP anonymizing function offered by Google, which will delete the last 8 digits (type IPv4) or the last 80 bits (type IPv6) of your IP address. Moreover, Google is certified under the &nbsp;<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank">EU-US Privacy Shield</a>
//                                                                                 , which ensures that an adequate level of data protection is maintained with respect to the processing of data by Google in the US.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 You may revoke your consent to the use of web analysis at any time, either by downloading and installing the &nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank">provided Google Browser Plugin</a>
//                                                                                 &nbsp;or by administrating your consents in the &nbsp;<a href="/node/1976#settings">above table</a>
//                                                                                 , in which case an opt-out cookie will be placed. Both options will prevent the application of web analysis only as long as you use the browser on which you installed the plugin and do not delete the opt-out cookie.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 Further information on Google Analytics is available in the &nbsp;<a href="https://www.google.com/analytics/terms/us.html" target="_blank">Google Analytics Terms of Use</a>
//                                                                                 , the &nbsp;<a href="https://support.google.com/analytics/answer/6004245?hl=en-GB" target="_blank">Privacy and Data Protection Guidelines of Google Analytics</a>
//                                                                                 &nbsp;and in the &nbsp;<a href="http://www.google.com/intl/en/policies/privacy" target="_blank">Google Privacy Policy</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>Online behavioral advertising with Google</h5>
//                                                                             <p>
//                                                                                 This Website uses an online behavioral advertising service of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google“).<br>
//                                                                                 Google will analyze your use of this Website. To this purpose, Google uses the cookies described in more detail in the &nbsp;<a href="/node/1976#settings">above table</a>
//                                                                                 . The information collected by Google in connection with your use of our Website (e.g. the referring URL, our webpages visited by you, your browser type, your language settings, your operating system, your screen resolution) will be transmitted to a server of Google in the US, where it will be stored and analyzed. We and our partner Google will use this information to better tailor our advertisements to you and your interests, to limit the number of times you are shown the same advertisement, to evaluate the efficiency of promotional campaigns, and to better comprehend the behavior of visitors after they have looked at a certain ad. When you visit another website of the so-called “Google Display Network”, customized pop-ups tailored to your interests can be presented to you on the basis of the information collected on our Website.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 Google is certified under the EU-US Privacy Shield, which ensures that an adequate level of data protection is maintained with respect to the processing of personal data by Google in the US. You may withdraw your consent to this transfer of information to Google for online behavioral advertising purposes at any time either by administrating your consents in the &nbsp;<a href="/node/1976#settings">above table</a>
//                                                                                 , in which case an opt-out cookie will be placed, or by downloading and installing the &nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank">Google Browser Plugin &nbsp;</a>
//                                                                                 offered by Google. Both options will prevent the use of online behavioral advertising services only as long as you use the browser on which you installed the plugin and do not delete the opt-out Cookie.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 Google is responsible for processing your personal data which Google collects directly from our Website for online behavioral advertisement services. Since we have no control over personal data collected and processed by third parties, we are not in a position to provide binding information regarding the scope and purpose of such processing of your personal data. Thus, please visit Google’s information on data privacy to receive further information on how Google processes your personal data and for how long. At the time of preparation of this privacy statement, Google’s information was available on the &nbsp;<a href="https://www.google.com/intl/en-GB/policies/technologies/ads/" target="_blank">Google Data Protection Guidelines for Advertising</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>Online behavioral advertising with Facebook</h5>
//                                                                             <p>
//                                                                                 This Website uses an online behavioral advertising service of Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland (using Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA as subprocessor) (“<strong>Facebook</strong>
//                                                                                 ”).
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>Facebook will analyze your use of this Website. To this purpose, we use the cookies described in more detail in the above table. The information collected by Facebook in connection with your use of our Website will be transmitted to a server of Facebook in the US, where it will be stored and analyzed. These information include the referring URL, your browser type, your language settings, your operating system, your screen resolution and further information depending on the implemented pixel events (e.g. “purchase”: processes the type, ID and number of the selected item as well as payment information and currency; “search”: processes the search string, when you search for a product on our website; “view content”: processes the content ID, name, type, currency and value). We and our partner Facebook will use this information to better tailor our advertisements to you and your interests, to limit the number of times you are shown the same advertisement, to evaluate the efficiency of promotional campaigns, and to better comprehend the behavior of visitors after they have looked at a certain ad. When you visit your Facebook timeline or another websites of Facebook’s advertisement network (so called “Audience Network”), customized feeds tailored to your interests can be presented to you on the basis of the information collected on our Website.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>Facebook Inc. is certified under the EU-US Privacy Shield, which ensures that an adequate level of data protection is maintained with respect to the processing of personal data by Facebook in the US.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 You may withdraw your consent to this transfer of information to Facebook for online behavioral advertising purposes at any time by administrating your consent in the &nbsp;<a href="/node/1976#settings">above table</a>
//                                                                                 , in which case an opt-out cookie will be placed. Please note that this will only prevent any transfer of information to Facebook as long as you do not delete the opt-out Cookie.
//                                                                             </p>
//                                                                             <p>
//                                                                                 <br>
//                                                                                 Facebook is responsible for processing your personal data which Facebook collects directly from our Website for online behavioral advertisement services. Since we have no control over personal data collected and processed by third parties, we are not in a position to provide binding information regarding the scope and purpose of such processing of your personal data. Thus, please visit Facebook’s information on data privacy to receive further information on how Facebook processes your personal data and for how long. At the time of preparation of this privacy statement, Facebook’s information was available on their &nbsp;<a href="https://www.facebook.com/about/privacy" target="_blank">Privacy Policy</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>Online behavioral advertising with LinkedIn</h5>
//                                                                             <p>
//                                                                                 The LinkedIn Insight Tag enables the collection of data regarding members’ visits to your website, including the URL, referrer, IP address, device and browser characteristics (User Agent), and timestamp. The IP addresses are truncated or (when used for reaching members &nbsp;<a href="https://www.linkedin.com/help/lms/answer/111735" target="_blank">across devices</a>
//                                                                                 ) hashed, and members’ direct identifiers are removed within seven days in order to make the data pseudonymous. This remaining pseudonymized data is then deleted within 180 days.<br>
//                                                                                 LinkedIn does not share the personal data with the website owner, it only provides reports (which do not identify you) about the website audience and ad performance. LinkedIn also provides retargeting for website visitors (up to 90 days after the visit), enabling the website owner to show personalized ads off its website by using this data, but without identifying the member. We also use data that does not identify you to improve ad relevance and reach members &nbsp;<a href="https://www.linkedin.com/help/lms/answer/111735" target="_blank">across devices</a>
//                                                                                 . LinkedIn members can control the use of their personal data for advertising purposes &nbsp;<a href="https://www.linkedin.com/psettings/advertising/actions-that-showed-interest" target="_blank">through their account settings</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>Online behavioral advertising with Trade Desk</h5>
//                                                                             <p>
//                                                                                 This website uses The Trade Desk, a so-called targeted advertising service, provided by our partner The Trade Desk, Inc., 42 N. Chestnut Street, Ventura, CA 93001 (“<strong>Trade Desk</strong>
//                                                                                 ”).<br>
//                                                                                 Trade Desk is used for so-called remarketing of advertising based on your behavior on our website. You may withdraw your consent to this type of analysis of your use of this Website at any time either by administrating your consents in the above table, in which case an opt-out cookie will be placed, or by visiting http://www.adsrvr.org/ to opt-out of the Trade Desk services. Both options will prevent the use of web analysis only as long as you use the browser on which you made your choice and do not delete the opt-out Cookie. Further information on Trade Desk is available at &nbsp;<a href="https://www.thetradedesk.com/general/privacy-policy" target="_blank">https://www.thetradedesk.com/general/privacy-policy</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>4. Use of contact forms</strong>
//                                                                             </p>
//                                                                             <p>You can contact us directly via the contact forms available on our Website. In particular, you may provide us with the following information:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Name, surname &nbsp;and title</li>
//                                                                                 <li>Country</li>
//                                                                                 <li>Contact data (e.g. e-mail address, phone number)</li>
//                                                                                 <li>Message</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>We collect, process and use the information provided by you via the contact forms exclusively for the processing of your specific request.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>5. Subscription to our newsletter</strong>
//                                                                             </p>
//                                                                             <p>On our Website, you may subscribe to receive our newsletter. Based on your prior consent, we will collect and use the email address you indicate for providing you with the newsletter (Art. 6(1)(a) General Data Protection Regulation).If you wish to receive a customized newsletter, you may, on a voluntary basis, provide us with the following additional information:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Name and surname</li>
//                                                                                 <li>Title</li>
//                                                                                 <li>Media, Department</li>
//                                                                                 <li>City, Country</li>
//                                                                                 <li>Language</li>
//                                                                                 <li>Subject(s) of interest</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>For subscription to our newsletter we use the so-called double opt-in procedure. After you have subscribed to the newsletter on our Website, we will send you a message to the indicated email address asking for your confirmation. If you do not confirm your subscription, your subscription will automatically be deleted. In order to prevent any misuse of your personal data, we will log your subscription and confirmation, filing the IP address you use when subscribing, the time of your subscription and confirmation, the messages sent by us regarding your subscription, and the wording of your subscription and confirmation.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 You may at any time with future effect revoke your consent to receive our newsletter. To declare that you wish to unsubscribe, you may use the respective link included in all newsletters, or refer to the &nbsp;<a href="#contact">contacts indicated below</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>6. External services or content on our Website</strong>
//                                                                             </p>
//                                                                             <p>We include third-party services and/or content on our Website. When you use such third-party services or when third-party content is displayed, communication data are exchanged between you and the respective provider for technical reasons.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>The respective provider of the services or content may also process your data for own additional purposes. To the best of our knowledge, we have configured the services and content of providers known to process data for own purposes in such a way that either any communication for other purposes than to present their services or content on our Website is blocked, or communication only takes place once you have actively opted to use the respective service. However, since we have no control over data collected and processed by third parties, we are not in a position to provide binding information regarding the scope and purpose of such processing of your data.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>For further information regarding the scope and purpose of such collection and processing of your data, please consult the privacy statements of the providers whose services and/or content we include and who are responsible for the protection of your data in this context:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>
//                                                                                     <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">YouTube (videos)</a>
//                                                                                 </li>
//                                                                                 <li>
//                                                                                     <a href="https://www.eqs.com/about-eqs/data-protection/" target="_blank">EQS Group AG (stock charts)</a>
//                                                                                 </li>
//                                                                                 <li>
//                                                                                     <a href="https://www.monotype.com/legal/privacy-policy/web-font-tracking-privacy-policy" target="_blank">Webfonts provided by Monotype Imaging Holdings Inc.</a>
//                                                                                 </li>
//                                                                                 <li>
//                                                                                     <a href="https://www.google.com/intl/de/policies/privacy/" target="_blank">Google Maps</a>
//                                                                                 </li>
//                                                                                 <li>
//                                                                                     <a href="/node/1976#tv-footage">TV-Footage</a>
//                                                                                 </li>
//                                                                                 <li>
//                                                                                     <a href="/node/1976#stockholders-portal">Stockholders’ Portal</a>
//                                                                                 </li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>7.&nbsp;Registration for closed user groups</strong>
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>a) TV-Footage</h5>
//                                                                             <p>Our TV-Footage material is only offered to journalists to support editorial coverage of Bayer. We check your access authorization and once your right of access has been verified, we will create an user account for you in which we will file the following personal data:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Name and surname</li>
//                                                                                 <li>Expert group, tv station/editorial department</li>
//                                                                                 <li>Contact data (incl. email address and phone number)</li>
//                                                                                 <li>Country and city</li>
//                                                                                 <li>User name and password</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>b) Limited access for special user groups</h5>
//                                                                             <p>There are further specific information that we can only share with a limited group of people. Therefore we file the following personal data:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Name and surname</li>
//                                                                                 <li>User name and password</li>
//                                                                                 <li>E-mail address</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>We process this personal data in order to provide you with an access to our website. They are automatically deleted in case you deactivate your user account.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>c) Stockholders‘ Portal</h5>
//                                                                             <p>
//                                                                                 The stockholders‘ portal is only offered to stockholders of Bayer AG. The login for our stockholders’ service is handled by the service provider &nbsp;<a href="https://www.linkmarketservices.de/datenschutz.html" target="_blank">Link Market Services GmbH</a>
//                                                                                 . We process these personal data provided by Link Market Services in order to allow your access to our website. These data are automatically deleted at the fixed date November 30th &nbsp;after one year in case you deactivate your user account or sell your shares in Bayer AG. You will find your data for the first log-in to our Stockholders' Portal in the invitation to the Annual Stockholders' Meeting of Bayer AG.
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>8. Information on side effects and quality complaints</strong>
//                                                                             </p>
//                                                                             <p>
//                                                                                 This Website is not intended or designed for communications regarding side effects, lack of therapeutic effect, medication errors, grey market products/counterfeit medicine, incorrect or off-label use, quality complaints and/or other issues regarding the safeness or quality of Bayer products. If you wish to report side effects or make a quality complaint, please contact your health care professional (e.g. physician or pharmacist), your local health authority, or use our &nbsp;&nbsp;<a href="https://pharma.bayer.com/report-side-effect" target="_blank">Website for the report of undesirable side effects</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>If you nevertheless report to us undesirable side effects or other issues regarding the safeness or quality of Bayer products, we will be legally bound to deal with your communication and may have to contact you for clarification purposes. Subsequently, we may have to notify the competent health authorities of the issues reported by you. In this context, your information will be forwarded in pseudonymized form, i.e. no information by which you may be directly identified will be passed on. We may also have to forward these pseudonymized notifications to our group companies and cooperation partners, to the extent these are likewise obliged to notify their respectively competent health authorities.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 Further information about data privacy and side effect reporting are available in the &nbsp;<a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="db4c72f0-870d-42aa-ae7b-8867a9dafed3" href="/en/privacy-statement-pv" title="privacy statement pv">Bayer Privacy Statement for Pharmacovigilance Data</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>9. Use of Publication Order</strong>
//                                                                             </p>
//                                                                             <p>On our Website, you may order/subscribe many of our publications. Based on your prior consent, we will collect and use the address details you indicate for providing you with the publication:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Surname</li>
//                                                                                 <li>Address (Street, Post code, Town or City, State/Province, Country)</li>
//                                                                                 <li>Email address</li>
//                                                                                 <li>Phone number</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>You are welcome to provide us with the following optional information:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Title</li>
//                                                                                 <li>First name</li>
//                                                                                 <li>Company</li>
//                                                                                 <li>Department</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 <strong>10. User Surveys</strong>
//                                                                             </p>
//                                                                             <p>Participation in the user surveys conducted from time to time on our website is voluntary. We use functional cookies to carry out the user surveys. The technical information recorded by the user survey is the same information that is recorded when users visit the website (see above). Your responses submitted during the user survey will not be linked to your personal data such as your IP address.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>II. Transfer of data for commissioned processing</h5>
//                                                                             <p>For the processing of your data we will to some extent use specialized service contractors. Such service contractors are carefully selected and regularly monitored by us. Based on respective data processor agreements, they will only process personal data upon our instruction and strictly in accordance with our directives.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h5>III. Processing of data outside the EU / the EEA</h5>
//                                                                             <p>Your data will in part also be processed in countries outside the European Union (“EU”) or the European Economic Area (“EEA”), which may have a lower data protection level than European countries. In such cases, we will ensure that a sufficient level of protection is provided for your data, e.g. by concluding specific agreements with our contractual partners (copy available on request), or we will ask for your explicit consent to such processing.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>B. Information regarding your rights</h2>
//                                                                             <p>The following rights are in general available to you according to applicable data privacy laws:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <ul>
//                                                                                 <li>Right of information about your personal data stored by us;</li>
//                                                                                 <li>Right to request the correction, deletion or restricted processing of your personal data;</li>
//                                                                                 <li>Right to object to a processing for reasons of our own legitimate interest, public interest, or profiling, unless we are able to proof that compelling, warranted reasons superseding your interests, rights and freedom exist, or that such processing is done for purposes of the assertion, exercise or defense of legal claims;</li>
//                                                                                 <li>Right to data portability;</li>
//                                                                                 <li>Right to file a complaint with a data protection authority;</li>
//                                                                                 <li>You may at any time with future effect revoke your consent to the collection, processing and use of your personal data. For further information please refer to the chapters above describing the processing of data based on your consent.</li>
//                                                                             </ul>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 If you wish to exercise your rights, please address your request to the contact form or to our &nbsp;<a href="#contact">company data protection office</a>
//                                                                                 &nbsp;indicated below.
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 If you are a resident of California or Nevada in the United States, you may have additional rights regarding your personal data. More information may be found &nbsp;<a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="7cf46689-99ca-4b51-9178-a8b3948d5ed1" href="/en/us/global-us-privacy-statement-addendum" target="_blank" title="global-us-privacy-statement-addendum">here</a>
//                                                                                 .
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>
//                                                                                 <a id="contact">C. Contact</a>
//                                                                             </h2>
//                                                                             <p>
//                                                                                 For any questions you may have with respect to data privacy, please use the provided &nbsp;<a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="6ff2b313-c3a3-4826-b20a-cd8b9d68f2db" href="/en/contacting-data-privacy" title="Contacting Data Privacy">contact form</a>
//                                                                                 &nbsp;or contact our company data protection officer at the following address:
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 Group Data Protection Officer<br>
//                                                                                 Bayer AG<br>51368 Leverkusen, Germany
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>Bayer AG is designated as representative in the European Union for our non-European legal entities in accordance with Art. 27 GDPR. You may contact the representative at the following address:</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <p>
//                                                                                 Data Privacy Representative<br>
//                                                                                 Bayer AG<br>
//                                                                                 51368 Leverkusen, Germany<br>
//                                                                                 E-mail:&nbsp;<a href="mailto:dp-representative@bayer.com">dp-representative@bayer.com</a>
//                                                                             </p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>D. Amendment of Privacy Statement</h2>
//                                                                             <p>We may update our Privacy Statement from time to time. Updates of our Privacy Statement will be published on our Website. Any amendments become effective upon publication on our Website. We therefore recommend that you regularly visit the site to keep yourself informed on possible updates.</p>
//                                                                             <p style="margin-bottom:10px">&nbsp;</p>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>`,
//       "conditionsOfUseHTMLText":`<div class="content-area-inner">
//                                                             <div class="field field--name-field-content field--type-entity-reference-revisions field--label-hidden field__items">
//                                                                 <div class="field__item">
//                                                                     <div class="default-schema no-image paragraph paragraph--type--jumbotron paragraph--view-mode--default">
//                                                                         <div class="content-wrapper">
//                                                                             <h1 class="heading_h1_h2">
//                                                                                 <div class="field field--name-field-title field--type-string field--label-hidden field__item">General Terms and Conditions of Use</div>
//                                                                             </h1>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div class="field__item">
//                                                                     <div id="91966" data-anchor-link-pid="pid-fb6baa117aee8c39b685702aabb2d725" class="paragraph paragraph--type--html-editor paragraph--view-mode--default">
//                                                                         <div class="text-formatted field field--name-field-html-editor field--type-text-long field--label-hidden field__item">
//                                                                             <p>Access to and use of this website are subject to the following terms and conditions. Only use this website if you accept these terms and conditions. This website was developed and is managed by Bayer Benelux (hereinafter referred to as Bayer). We reserve the right to discontinue this website or to change this website, the General Terms and Conditions of Use, the General Terms and Conditions and the Terms and Conditions of Sale and Delivery in full or in part. We may make these changes at our sole discretion and without prior notice. We therefore request that you review the terms and conditions upon your next visit so that you can take note of any changes.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Waiver of use and enjoyment</h2>
//                                                                             <p>All information, documents and images published on this website are the exclusive property of Bayer. All permission for use is granted on condition that the copyright notice appears on all copies, that this information is used exclusively for personal purposes, that it is not used for commercial purposes, that the information is not altered in any way and that the images on the website are only used with the accompanying text.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Trademarks and copyright</h2>
//                                                                             <p>All product names on this website are trademarks of Bayer unless otherwise indicated or otherwise recognizable as third party rights. Any unauthorized use of these trademarks or other materials is expressly prohibited and infringes copyright, trademark or other industrial property laws.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Limited liability</h2>
//                                                                             <p>Bayer has carefully compiled the detailed information on this website professionally and to the best of its knowledge, using internal and external sources. We do our best to expand and update this information continuously. The sole purpose of the information on this website is to present Bayer and its products and services. This does not, however, imply any guarantee or statement, either express or implied, as to the completeness or accuracy of the information on this website. Please note that there is a possibility that this information is no longer up to date. We therefore recommend checking all information accessed on this website before using it in whatever form. The advice given on this website does not relieve you of the obligation to verify this advice yourself - especially as regards our safety data sheets and technical specifications - as well as concerning our products and the extent to which they are suitable for the intended process and purpose. Should you require advice or instructions concerning our products or services, please contact us directly. Users of this website acknowledge that they gain access to the website and its content at their own risk. Neither Bayer nor the third parties involved in the creation, production or transmission of this website are liable for any damage or disadvantage arising from access or inability to access this website, from the use or inability to use this website, or from the fact that you have relied on information provided on this website.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Third-party vendor websites/links</h2>
//                                                                             <p>This website contains links/references to third-party websites. The provision of such links does not imply that Bayer endorses the content of these websites. Nor does Bayer accept any liability for the availability or content of such websites or for any damage or disadvantage resulting from the use of their content, in any form whatsoever. Bayer does not guarantee that linked pages contain information of consistent quality. Links to other websites are provided solely for the convenience of our visitors. Access to these websites is at the user’s risk. The selection of links must not in any way limit users to the linked pages.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Information you provide</h2>
//                                                                             <p>The user of this website bears full responsibility for the content and accuracy of the information he or she sends to Bayer and ensures that no third-party rights are violated in the process. The user authorizes Bayer to store and use this information for statistical or other specified business purposes, unless the information contains personal data beyond the main data or usage data. In particular, Bayer is entitled to use the content of such messages, including ideas, inventions, blueprints, techniques, and expertise contained therein for a variety of purposes, such as the development, production, and/or marketing of products or services, and to reproduce such information without any restriction and make it available to third parties.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>International users</h2>
//                                                                             <p>This website is controlled, managed and updated by Bayer Benelux. The website is intended for use in the Benelux only. Bayer does not provide any guarantee that the information contained on this website is also accurate for places outside the Benelux and, in particular, that the products and services will be available there in the same form, to the same extent, or under the same conditions. If you are located outside the Benelux and have obtained access to this website or downloaded data from this location, please note that it is your responsibility to comply with local laws. The packaging, packaging size, letters and markings of the products listed on this website may vary from country to country. In the United States, activities are carried out by the Bayer Corporation. Customers in the United States are requested to contact this company. (In the USA, business is conducted by Bayer Corporation. Customers in the USA are requested to address this entity.)</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Sale of Bayer Products</h2>
//                                                                             <p>Our products are sold in accordance with the applicable version of our General Terms and Conditions of Sale and Delivery.</p>
//                                                                             <p>&nbsp;</p>
//                                                                             <h2>Sites with login: access rights</h2>
//                                                                             <p>Please note that you are obliged to protect your access rights from unauthorized use by third parties and that you must ensure that others cannot use them without your consent. If you become aware that there has been a breach of security impacting data available on the Bayer website you must immediately inform Bayer. This also applies if unauthorized persons have obtained your access rights or if you have indications that such access has become possible.</p>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>`,
//       "PK": "Netherlands"
//   }
//   });
//   return response
// }


const handlecountryChange = (evt) => {
    setcountryFilter(evt.target.value);
    setcountryLangFilter("English");
  };
  
  const handlecountryLanguageChange = (evt) => {
    setcountryLangFilter(evt.target.value);
  };
  useEffect(() => {
    getCountryLang(countryFilter ? countryFilter:countrySettings?.region  === "Global" ? "US" : countrySettings?.region)
   if (localStorage.getItem("LoggedIn") === "true") {
  userSettingsApi()
  }  
  
  //  updateCountryLang()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryFilter,countryLangFilter]);  
  useEffect(() => {
    let afterLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.langCode === userDefaultLang)[0]?.lang
    let beforeLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.countryWithLangCode === countrySettings?.locale)[0]?.lang
  
   if (localStorage.getItem("LoggedIn") === 'false'){
//eslint-disable-next-line 
    langValue = beforeLogLang
   }else{
    langValue =afterLogLang
   }
   setcountryFilter(countryFilter ? countryFilter:countrySettings?.region  === "Global" ? "US" : countrySettings?.region)
  }, [countryFilter])
  
  useEffect(() => {
    let country_data
    const countrySettingsCall = async (countryFilter) => {
      let countryFiltering = countrySettings?.availableCountries.filter((item)=> item?.region === countryFilter)
      const countrySettingsUrl = `${config.baseApi}dc/config/country`;
      let country_settings = await axios({
        method: "get",
        url: `/${countryFiltering?.countryCode}`,
        baseURL: countrySettingsUrl,
        headers: { Authorization: "Bearer" }
      });
     country_data = country_settings.data.data;
      store.dispatch(fetchCountrySettingsSuccess(country_data)); 
      let afterLogLang = country_data && country_data?.languages?.filter(language => language?.langCode === userDefaultLang)[0]?.lang
      let beforeLogLang = country_data && country_data?.languages?.filter(language => language?.countryWithLangCode === country_data?.locale)[0]?.lang
    
     if (localStorage.getItem("LoggedIn") === 'false'){
        //eslint-disable-next-line 
      langValue = beforeLogLang
     }else{
      langValue =afterLogLang
     }
    };
    countrySettingsCall(countryFilter)
   

  }, [countryFilter, langValue, countryLangFilter])
  
 

  useEffect(() => {
    var GA = "";
    if (isTracking === "allow") {
      GA = "on";
    } else {
      GA = "off";
    }
    setValue(GA);
  }, [isTracking]);

  useEffect(() => {
    if (upperDivisionRef.current) {
      upperDivisionRef.current.innerHTML = countrySettings?.privacyText;
    }
    if (lowerDivisionRef.current) {
      lowerDivisionRef.current.innerHTML =
        countrySettings?.additionalPrivacyText;
    }
  }, [countrySettings]);

  const handleChange = event => {
    var userInput = event.target.value;
    setValue(userInput);
    handleCookie(userInput, reDirect);
  };
  function handleCookie(userChoice, pageReload) {
    /* switch (userChoice) {
      case "ga-on":
        delete_cookie("cookieconsent_status");
        setCookie(
          "cookieconsent_status",
          "allow",
          180,
          window.location.hostname
        );
        break;
      case "ga-off":
        window["ga-disable-GTM-NHKHMPH"] = true;
        delete_cookie("cookieconsent_status");
        break;
      case "on":
        delete_cookie("cookieconsent_status");
        setCookie(
          "cookieconsent_status",
          "allow",
          180,
          window.location.hostname
        );
        break;
      case "off":
        delete_cookie("cookieconsent_status");
        break;
      default:
        break;
    } */
    if (userChoice === "off") {
      window["ga-disable-GTM-NHKHMPH"] = true;
      delete_cookie("cookieconsent_status");
    } else {
      delete_cookie("cookieconsent_status");
      setCookie("cookieconsent_status", "allow", 180, window.location.hostname);
    }
    pageReload();
  }
  function reDirect() {
    window.location.reload();
  }
  function delete_cookie(name) {
    document.cookie =
      `${name}=; Max-Age=0; path=/; domain=` + window.location.hostname;
  }
  function setCookie(cname, cvalue, exdays, domain) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domain;
  }
  // eslint-disable-next-line
  const heading = () => {
    let heading =
      getCountryCode() === "nl" ? "Privacyverklaring" : "Datenschutzerklärung";
    return heading;
  };

  let afterLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.langCode === userDefaultLang)[0]?.lang
  let beforeLogLang = countrySettings && countrySettings?.languages?.filter(language => language?.countryWithLangCode === countrySettings?.locale)[0]?.lang

 if (localStorage.getItem("LoggedIn") === 'false'){
  langValue = beforeLogLang
 }else{
  langValue =afterLogLang
 }

const DataFetchingFunc =(url)=>{
    if(url.includes("privacy")){
        return(
            <>
            {countryFilterData &&  countryFilterData.map((item, index) => (
        <div className="top-section" dangerouslySetInnerHTML={{ __html: item.privacyUpperHTML }} />
       ))}
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Zweck und Inhalt</th>
              <th>Aussteller</th>
              <th>Lebensdauer</th>
              <th>Einwilligung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Website-Analyse mit Google Analytics und Hotjar</td>
              <td>
                Diese Cookies vergeben Ihrem Gerät eine zufällig generierte ID,
                mit der wir Ihr Gerät bei Ihrem nächsten Be-such wiedererkennen.
                Details zur Website-Analyse finden Sie weiter unten im
                entsprechenden Kapitel.
              </td>
              <td>Google</td>
              <td>6 Monate</td>
              <td>
                <FormControl component="fieldset">
                  <RadioGroup value={value} onChange={handleChange}>
                    <FormControlLabel
                      value="on"
                      control={<StyledRadio />}
                      label="On"
                    />
                    <FormControlLabel
                      value="off"
                      control={<StyledRadio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </td>
            </tr>
            {/* <tr>
              <td>Website-Analyse mit Hotjar</td>
              <td>
                Diese Cookies vergeben Ihrem Gerät eine zufällig generierte ID,
                mit der wir Ihr Gerät bei Ihrem nächsten Be-such wiedererkennen.
                Details zur Website-Analyse finden Sie weiter unten im
                entsprechenden Kapitel.
              </td>
              <td>HotJar</td>
              <td>6 Monate</td>
              <td>
                <FormControl component="fieldset">
                  <RadioGroup value={value} onChange={handleChange}>
                    <FormControlLabel
                      value="on"
                      control={<StyledRadio />}
                      label="On"
                    />
                    <FormControlLabel
                      value="off"
                      control={<StyledRadio />}
                      label="Off"
                    />
                  </RadioGroup>
                </FormControl>
              </td>
            </tr> */}
          </tbody>
        </table>
          {countryFilterData &&  countryFilterData.map((item, index) => (
         <div className="top-section" dangerouslySetInnerHTML={{ __html: item.privacyLowerHTML }} />
       ))}
            </>
        )
    }
    else if(url.includes("conditions")){
        return(
            <>
             {countryFilterData &&  countryFilterData.map((item, index) => (
        <div className="placeholder" dangerouslySetInnerHTML={{ __html: item.conditionsOfUseHTMLText }} />
       ))}
            </>
        )
    }
    else if(url.includes("imprint")){
        return(
            <>
             {countryFilterData &&  countryFilterData.map((item, index) => (
        <div className="placeholder" dangerouslySetInnerHTML={{ __html: item.imprintHTMLText}} />
       ))}
            </>
        )
    }
}

  return (
    <Container className="footer-page">
      <div className="content">
        <div className="countryAndLangDropdown">
            <FormControl variant="outlined" style={{padding:"1px", marginTop: "2%", width: "100%" }} >
        <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={countryFilter ? countryFilter:countrySettings?.region  === "Global" ? "US" : countrySettings?.region}
          label="Select Country"
          onChange={handlecountryChange}
          defaultValue={countryFilter ? countryFilter: countrySettings?.region === "Global" ? "US" : countrySettings?.region }
          displayEmpty    
        >
      {countrySettings?.availableCountries?.map((item, index) => {
        return (
          <MenuItem  key={index} value={item?.region}>{item?.region}</MenuItem>
      );
    })}
        </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ padding:"1px",marginTop: "2%", width: "100%" }} >
              <InputLabel id="demo-simple-select-label">Select Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={countryLangFilter ? countryLangFilter : langValue ||""}
                label="Select Language"
                onChange={handlecountryLanguageChange}
                defaultValue={countryLangFilter ? countryLangFilter : langValue ||""}
              >
           {countryLangData && countryLangData.length > 0 ? countryLangData.map((item, index) => (
                <MenuItem key={index} value={item?.SK}>{item?.SK}</MenuItem>
              )) : <MenuItem value="" disabled>No languages available</MenuItem>}
              </Select>
            </FormControl>
        </div>
        {DataFetchingFunc(window.location.pathname)}
       
          </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});
export default connect(mapStateToProps)(DropDownFilters);
