import React,{ useEffect, useState}  from "react";
import { Button, Container, Modal, Form } from 'react-bootstrap'
import "./Administration.scss";
import config from "../../config.js";
import {getCountryCode} from "../../util/Helper.js";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import IconButton from "@mui/material/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LaunchIcon from '@mui/icons-material/Launch';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles} from '@mui/styles';
import { Link } from "react-router-dom";
import { FormControl, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import { MenuItem } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {updateRoleSettings, updateRenderCountryCode} from "../../redux";
import {
  fetchProductsSuccess,
  fetchAssetsSuccess,
  setFilterBrandName,
  setFilterAssetTypes,
  setFilterFileTypes,
  fetchCountrySettingsSuccess,
} from "../../redux";
import _ from "lodash";
import store from "../../redux/store";
const axios = require("axios");
const rolelistUrl = `${config.baseApi}dc/exports/rolelist`;
const analyticsUrl = `${config.baseApi}dc/entities/brandReport`;
const translationDataListUrl = `${config.baseApi}dc/translation/getTranslations`;
const countriesRoleDataUrl=`${config.baseApi}dc/config/getCountryAndRoles`
let  filteredOptionList  =[]
let filteredCountryList =[]
const Administration= (props) => {
  let { RoleSettings } = { ...props };
const [showModal, setShowModal] = useState(false);
const [showModalRole, setShowModalRole] = useState("");
const [editUserName, setEditUserName] = useState(false);
const [editRoleId, setEditRoleId] = useState("");
const [editCountry, setEditCountry] = useState("");
const [editIndex, setEditIndex] = useState();
const [value, setValue] = useState('1');
const [optionList, setOptionList] = useState([]);
const [data, setData]= useState([]);
const [gigyaRole, setGigyaRole] = useState();
const [brandReport, setBrandReport] = useState([]);
const [translationDataList, setTranslationDataList] = useState([]);
// eslint-disable-next-line
const [selected, setSelected] = useState("");
// eslint-disable-next-line
const [selectedcode, setSelectedCode] = useState("");
const [countryFilter, setcountryFilter] = useState("All Data");
const [countryOptions, setCountryOptions] = useState([]);
const [countryForEdit,setCountryForEdit] = useState([]);
const [retailerData, setRetailerData] = useState([]);
const [showDeleteUser, setShowDeleteUser] = useState(false);
const [deleteUserName, setDeleteUserName] = useState(false);
const [deleteUserID, setDeleteUserID] = useState(false);
const [countriesRoleList, setCountriesRoleList] = useState([]);
const [showModalforCountries, setShowModalforCountries] = useState(false);
// eslint-disable-next-line
const [editIndexForCountries, setEditIndexForCountries] = useState()
const [editCountryForCountries, setEditCountryForCountries] = useState("");
const [editRoleForCountries, setEditRoleForCountries] = useState([])
const [editCountryForCountriesCode, setEditCountryForCountriesCode] = useState("");
const [roleId, setRoleId] = useState("");

const userId= localStorage.email
const handleChange = (event,newValue) => {
  setValue(newValue);
};

const getAnalyticsData = async () => {
 
  let response
  if(JSON.parse(localStorage.getItem('user_role')) === "Global Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/entities/brandReport`,     
      )
  } 
   else if(JSON.parse(localStorage.getItem('user_role')) === "Country Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/entities/brandReport`,  
          {
              headers: {
                  "X-Country-Code": `${getCountryCode()}`,

              }
          }
      )
   }
  setBrandReport(response?.data?.data);
  return response?.data;
};

const getRetailerData = async () => {
  let response
  if(JSON.parse(localStorage.getItem('user_role')) === "Global Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/exports/getallretailers`,     
      )
  } 
   else if(JSON.parse(localStorage.getItem('user_role')) === "Country Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/exports/getallretailers`,  
          {
              headers: {
                  "X-Country-Code": `${getCountryCode()}`,

              }
          }
      )
   }
  setRetailerData(response?.data?.message);
  return response;
  
};

const getLabelTranslationListData = async () => {
  const response = await axios.get(translationDataListUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  })
  setTranslationDataList(response.data.data);
  return response?.data;
};

const getCountriesRoleData = async () => {
  let updatedData
  const response = await axios.get(countriesRoleDataUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  })
  if(JSON.parse(localStorage.getItem('user_role')) === "Global Admin"){
     updatedData = response?.data?.data.map(country => {     
      const roles = country.availbleRolesForTheCountry
      return roles;
  }).flat();
  } 
   else if(JSON.parse(localStorage.getItem('user_role')) === "Country Admin"){
     updatedData = response?.data?.data.map(country => {
      // eslint-disable-next-line no-unused-expressions
      const roles = country.availbleRolesForTheCountry.filter(role =>  role.countryCode.toLowerCase() === getCountryCode()) ;
      return roles;
  }).flat();
   }
  setCountriesRoleList(updatedData)

  return response?.data;
};

const getRoles = async () => {
const response = await axios.get(rolelistUrl, {
  headers: {
    "X-Country-Code": `${getCountryCode()}`
  }
})
setOptionList(response?.data?.message);
return response?.data;
};

const getUsers = async (code) => {
  let response
  if(JSON.parse(localStorage.getItem('user_role')) === "Global Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/exports/userlists`, 
        {
          headers: {
              "X-USER-IDENTIFIER": 'emailId'

          }
      }    
      )
  } 
   else if(JSON.parse(localStorage.getItem('user_role')) === "Country Admin"){
      response = await axios
      .get(
        `${config.baseApi}dc/exports/userlists`,  
          {
              headers: {
                  "X-Country-Code": code,
                  "X-USER-IDENTIFIER": 'emailId'

              }
          }
      )
   }

  let data = prepareAdminData(response?.data?.data);
  setData(data);
  let personaluserData = response?.data?.data.filter((item)=> item.EmailId === userId)
  // personaluserData &&  props.updateRoleSettings(personaluserData?.[0]?.role)
  personaluserData && props.updateRenderCountryCode(personaluserData?.[0]?.countryCode)
  console.log("personaluserData", personaluserData, "[0]", personaluserData[0], "code", code, "Roles", RoleSettings)
  return response?.data;
};
const getCountries = async () => {
  const response = await axios
      .get(
          `${config.baseApi}dc/config/getCountries`,

      )

      const obj = {countryCode: "All Data", region: "All Data"};
      setCountryOptions([...response.data.data, obj])
      setCountryForEdit(response.data.data)
  return response.data;
};
// eslint-disable-next-line
const getAnalyticsDataDe = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "de"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataNl = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "nl"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataEs = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "es"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataBr = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "br"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataFr = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "fr"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataUs = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "us"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
// eslint-disable-next-line
const getAnalyticsDataBe = async () => {
  const response = await axios.get(analyticsUrl, {
    headers: {
      "X-Country-Code": "be"
    }
  })
  setBrandReport(response?.data?.data);
  return response?.data;
};
const postGigyaID = async () => {
  const response = await axios({
    method: "get",
    url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
    data: {
      "useAdministration": {
        "Gigya_ID": userId,
      }
    },
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  })
  setGigyaRole(response?.data?.data?.roleName);
}

useEffect(() => {
  getCountries()
  getRoles();
  getUsers(getCountryCode())
  postGigyaID();
  getAnalyticsData();
  getRetailerData();
  getCountriesRoleData()
  getLabelTranslationListData()
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [
  
]);

const handleShowEdit = (e,item,index) => {
  e.preventDefault();
  setEditIndex(index);
  setEditUserName(item.UserName);
  setEditRoleId(item.role?.id);
  setEditCountry(item.countryCode)
  setShowModal(true);
  setShowModalRole(item.role.rolename)
}

const handleEditForCountries =(e,item,index)=>{
  e.preventDefault();
  setEditIndexForCountries(index);
  setEditCountryForCountries(handleCountryName(item?.countryCode))
  setRoleId(item.SK)
  setEditCountryForCountriesCode(item.countryCode)
  setEditRoleForCountries(item.roleNameEditable)
  setShowModalforCountries(true)
}

const handleCloseForCountries =(e,item,index)=>{
  setShowModalforCountries(false)
}

const handleEditRoleForCountries =async()=>{

// eslint-disable-next-line
  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/config/editRoleForCountry/${roleId}/${editCountryForCountriesCode}/${editRoleForCountries}`,
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  })
  getCountriesRoleData()
  setShowModalforCountries(false)
}

// eslint-disable-next-line
const handleSelect = (event) => {
  setSelected(event.target.value);
  
    
};

const handleCloseDeleteUser = () => setShowDeleteUser(false);

const handleShowDeleteUserModal = (e,item,index) => {
  e.preventDefault();
  setDeleteUserName(item.UserName)
  setShowDeleteUser(true);
  setDeleteUserID(item.EmailId)
}

const editModal = async (roleid, countryId, index) => {
  const editedList = [...data];
  setData(editedList);  
  if (roleid === "") {
    return;
  }
  
  try {
    const response = await axios({
      method: "post",
      url: `${config.baseApi}dc/exports/updateuserrole`,
      data: {
        "gigyaId": editedList[index].id,
        "emailId": editedList[index].email,
        "roleId": roleid,
        "country": countryId.id
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    }).then(async() => {
      if(editedList[index].email === userId){
        getUsers(countryId.id ? countryId.id  : getCountryCode())
        postGigyaID()
        const brandNameUrl = `${config.baseApi}dc/brands`;
        const dataUrl = `${config.baseApi}dc/entities`;
        const fileTypesUrl = `${config.baseApi}dc/assets/filetypes`;
        const countrySettingsUrl = `${config.baseApi}dc/config/country`;
        function brandList() {
          return axios.get(brandNameUrl, {
            headers: {
         
              "X-Country-Code": `${countryId.id ? countryId.id  : getCountryCode()}`
            }
          }).then(res => res.data).then(response => {
            let products = [];
            let assets = [];
            const brands = response.data.filter;
           
            const removeDuplicates=(array, key) =>{
              return array.filter(
                (obj, index, self) =>
                  index ===
                  self.findIndex(
                    (item) => item[key].toLowerCase() === obj[key].toLowerCase()
                  )
              );
            }
          
            const formatBrandName=(name)=> {
              return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
            }
            const uniqueBrands = removeDuplicates(brands, 'brandName');
        
            let formattedBrands = uniqueBrands.map(brand => ({
              brandName: formatBrandName(brand.brandName),
              filterType: brand.filterType,
              count: brand.count
            }));
          
        
            store.dispatch(setFilterBrandName(formattedBrands));
            const brandSets = _.chunk(brands, 2);
            const promiseArray = brandSets.map(brands =>
              axios.post(
                dataUrl,
                {
                  brandNames: brands.map(brand => brand.brandName)
                },
                {
                  headers: {
                  
                    "X-Country-Code": `${countryId.id ? countryId.id  : getCountryCode()}`
                  }
                }
              )
            );
            return Promise.allSettled(promiseArray).then(response => {
              response.forEach(obj => {
                if (obj.status === "fulfilled") {
                  products.push(...obj.value.data.data.products);
                  (RoleSettings?.roleName === "Global Admin" || RoleSettings?.roleName === "Country Admin")
                  ? products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication"))
                  : products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication") &&  (item?.hideShowStatus?.[0]?.currentStatus ==="show" || !item?.hideShowStatus) )
                  assets.push(...obj.value.data.data.assets);
                   // eslint-disable-next-line no-unused-expressions
                  (RoleSettings?.roleName === "Global Admin" || RoleSettings?.roleName === "Country Admin")
                  ? assets 
                  : assets = assets.filter(item =>  !item?.hideShowStatus || item?.hideShowStatus?.[0]?.currentStatus ==="show"  )
        
                }
                if (obj.status === "rejected") {
                  console.log(obj.reason);
                }
              });
              store.dispatch(fetchProductsSuccess(products));
              store.dispatch(fetchAssetsSuccess(assets));
              let asset_types = [];
              assets.forEach(asset =>
                asset.asset_type.forEach(val =>
                  asset_types.push({ asset_type: val, filterType: "assetType" })
                )
              );   
              let unique_asset_types = _.uniqBy(asset_types, "asset_type.dcAssetType");
        
              const updatedArray = unique_asset_types.map(item => {
                if (
                  item.asset_type &&
                  item.asset_type.dcAssetType === 'eCommerce Product Shots'
                ) {
                  return {
                    ...item,
                    asset_type: {
                      ...item.asset_type,
                      dcAssetType: 'Product Shot',
                    },
                  };
                } else {
                  return item;
                }
              });
              let updatedArray_unique_asset_types = _.uniqBy(updatedArray, "asset_type.dcAssetType");
        
              store.dispatch(setFilterAssetTypes(updatedArray_unique_asset_types));
           
            });
          })
        
        }
        function fileType() {
          return axios.get(fileTypesUrl, {
            headers: {
              "X-Country-Code": `${countryId.id ? countryId.id  : getCountryCode()}`
            }
          }).then(res => res.data).then(response => {
            const fileTypes = response.data;
            store.dispatch(setFilterFileTypes(fileTypes));
          }).catch(error => {
            console.log(error.message);
          });;
        }
        
        const countrySettingsNew = async load => {
          let country_settingsNew = await axios({
            method: "get",
            url: `/${countryId.id ? countryId.id  : getCountryCode()}`,
            baseURL: countrySettingsUrl,
            headers: { Authorization: "Bearer" }
          });
          let country_data = country_settingsNew.data.data;
          store.dispatch(fetchCountrySettingsSuccess(country_data));
        
         
          return country_data;
        };
        
        brandList()
        fileType()
        countrySettingsNew()
      }
      else{
        getUsers(getCountryCode())
      }
         

    })

 
    handleClose();
    return response;
  } catch (error) {
    console.error("Error updating user role:", error);
  }
};


const columns = [
  { title: "User Name", field: "username" },
  { title: "ID",field: "id"},
  { title: "Last Login", field: "lastlogin"},
  { title: "Role", field: "role"},
  { title: "EmailId", field: "email", hidden:true},
  { title: "country", field: "country", hidden:true},
  { title: "Country", field: "CountryName"},
  { title: "Edit Action", field: "editaction"},
  { title: "Delete Action", field: "deleteAction"},

];


const handleCountryName=(code)=>{
  if (!countryForEdit || !Array.isArray(countryForEdit)) {
    return "Not Available";
  }
  let countryMapping = countryForEdit?.filter(item => item?.countryCode === code);
  let regionName = countryMapping.map((item)=> {return item.region});
  if (countryMapping.length === 0) {
    return "Not Availables";
  }
  else {
  return regionName[0];
  }
  }

function prepareAdminData(dataList) {
  const data = dataList && dataList.map((item, index) => {
    const lastLoginFormat = item.LastLogin;
    const lastLoginDate = new Date(lastLoginFormat);
    const currentLastLoginDate = lastLoginDate.getDate()+
    "-"+(lastLoginDate.getMonth()+1)+
    "-"+lastLoginDate.getFullYear();
      return {
        key: index, 
        username:  item.UserName, 
        id: item?.EmailId, 
        lastlogin: currentLastLoginDate, 
        role: item.role.roleName,
        country: item?.countryCode,
        email:item?.EmailId,
        CountryName: item?.countryName,
        editaction: <IconButton
                        color="inherit"
                        onClick={(e) => handleShowEdit(e,item,index)}
                      >
                        <Edit />
                    </IconButton>
                     ,
      deleteAction:<IconButton
                        color="inherit"
                        onClick={(e) => handleShowDeleteUserModal(e,item,index)}
                      >
                        <Delete/>
                    </IconButton> ,
      }
  })
  return data
}
const retailerColumns = [
  { title: "Name", field: "name" },
  { title: "country", field: "country", hidden: true },
  {
    title: "Country", field: "countryName",
    editComponent: ({ value, onChange, rowData }) => (
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Select Country"
          onChange={(event) => {
            const selectedCountryCode = event.target.value;
            const selectedCountry = countryForEdit.find(country => country.countryCode === selectedCountryCode);
            const selectedCountryName = selectedCountry ? selectedCountry.region : "Not Available";
            onChange(selectedCountryName);
          }}
          displayEmpty
          renderValue={value === "" ? undefined : () => value}
        >
            {gigyaRole === "Country Admin" ?

                      filteredCountryList.map((item, index) => { 
                        return (
                          <MenuItem key={index} id={item.countryCode} value={item.countryCode} > {item.region}</MenuItem>
                        )
                      }) :
                      countryForEdit.map((item, index) => {
                        return (
                          <MenuItem key={index} id={item.countryCode} value={item.countryCode} > {item.region}</MenuItem>
                        )
                      })
                    }
          
        </Select>
      </FormControl>
    )
  },
  { title: "Retailer Code", field: "code", editable: 'never' },
  { title: "PK", field: "PK", hidden: true },
  { title: "SK", field: "SK", hidden: true },
  {
    title: (
      <>
        Email ID <br />
        <em style={{ fontSize: "12px", color: "gray" }}>
          In case of multiple emails, please enter the emails separated by a comma (,)
        </em>
      </>
    ),
    field: "emailId",
    editPlaceholder: "In case of multiple emails, please enter the emails separated by a comma"
  }
];


const retailerTable = retailerData && retailerData.map((item,index) =>{
  return {
    key: index, 
    name:  item.retailerName,
    country: item.countryCode,
    countryName: handleCountryName(item?.countryCode),
    code: item.retailerCode,
    PK: item.PK,
    SK: item.SK,
    emailId: item.emailId
  }
})

const countriesColumns =[
  { title: "CountryCode", field: "countryCode" ,hidden:true},
  { title: "SK", field: "SK" ,hidden:true},
  { title: "Country", field: "country" },
  { title: "Roles",field: "roles"},
  { title: "Edit Action", field: "editaction"},
]

const countriesData = countriesRoleList && countriesRoleList.map((item,index) =>{
  return {
    key: index, 
    SK: item.SK,
    countryCode:item.countryCode,
    country:  handleCountryName(item?.countryCode),
    roles:item.roleNameEditable,
    editaction:
    
    item.roleNameEditable=== "HCP" || item.roleNameEditable=== "Global Admin"||
    item.roleNameEditable=== "Internal" || item.roleNameEditable=== "Country Admin"
    ?
    <div rel="tooltip" title="This role is not editable" class="wrap poptooltip">
    <div class="overlap"></div>
    <IconButton
    color="inherit"
    disabled
    onClick={(e) => handleEditForCountries(e,item,index)}>
     <Edit /> 
    </IconButton>
    </div>:
      <IconButton
    color="inherit"
    onClick={(e) => handleEditForCountries(e,item,index)}
  >
    <Edit />
</IconButton>

 ,
  }
})

const analyticsColumns = [
  { title: "Brand name", field: "brandName" },
  { title: "Amount of Products",field: "products"},
  { title: "Amount of Assets", field: "assets"},
  { title: "Quality Index", field: "qualityIndex"},
  { title: "Development from previous month(QI)", field: "previousQI"},
  { title: "Detailed View", field: "detailedView"},
  { title: "Country", field: "CountryName", hidden: true},
]

const analyticsData =brandReport && brandReport.map((item,index) =>{
  return {
    key: index, 
    brandName:  item.brandName,
    products: item.numberOfProducts, 
    assets: item.numberOfAssets,
    qualityIndex: item.qualityIndex,
    previousQI: item.previousQualityIndex,
    CountryName: item?.countryCode,
 
    detailedView: <Link to={{ pathname :`/home/administration/detailedreport/${item.brandName}?${selectedcode}` , 
    }} style={{color:"#000",textDecoration: "auto" }} >
                    <IconButton
                      color="inherit"
                    >
                      <LaunchIcon />
                    </IconButton> 
                </Link> 
  }
})



const options = {
  filterType: "dropdown",
  responsive: "scroll",
  print: false,
  download: false,
  checkbox: false,
  selectableRows: false,
  showTitle: false,
  addRowPosition: 'first',
  //actionsColumnIndex: -1,
  doubleHorizontalScroll: true
};



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const handleClose = () => {
  setShowModal(false) 
} 

const handlecountryChange = (evt) => {
  setcountryFilter(evt.target.value);
};

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
     display: "none",
    },
    "& .MuiTab-root.Mui-selected": {
      color: 'white !important',
      background:"#00617f !important"
    },
    "& hover ":{
      background:"#89d329 !important"
    }
  },
  table:{
    "& .MuiTableSortLabel-root":{
      fontSize: 16,
      color: "#00617f",
      fontWeight:600,
      fontFamily: 'Neue Helvetica',
  },
      "& .MuiTypography-root": {
    margin: "0",
    fontSize: "1rem",
    textAlign: "center"
}
},
tableLable:{
  "& .MuiTableSortLabel-root":{
    fontSize: 16,
    color: "#00617f",
    fontWeight:600,
    fontFamily: 'Neue Helvetica',
},
    "& .MuiTypography-root": {
  margin: "0",
  fontSize: "1rem",
  textAlign: "center"
}


},
})

// eslint-disable-next-line no-extend-native
String.prototype.includesOneOf = function(arrayOfStrings) {
  if(!Array.isArray(arrayOfStrings)) {
  throw new Error('includesOneOf only accepts an array')
  }
  return arrayOfStrings.some(str => this.includes(str))
  }


const headerTable = translationDataList &&  translationDataList?.[0]  &&  Object.keys(translationDataList?.[0]).sort(
  function(a, b){
    let result;
    if (a === "location" && b === "labels") {
      const dateA = a.length
      const dateB = b.length
      result = dateA - dateB;
    }
    if (a === "location" && b === "defaultValue") {
      const dateA = a.length
      const dateB = b.length
      result = dateA - dateB;
    }
    if (a === "defaultValue" && b === "labels") {
      const dateA = a.length
      const dateB = b.length
      result = dateA - dateB;
    }
    else{
      result = b.length-a.length
    }
    return result;
 }).map((header) => ( 
  {
  title:  translationDataList?.[0] && header.replace(/(^\w|\s\w)/g, m => m && m.toUpperCase()),
  field:  translationDataList?.[0] && header,
  editable: translationDataList?.[0] && header.includesOneOf(["location", "labels"])? "onAdd":"always",
  // editable: translationDataList?.[0] && header.includesOneOf(["location", "labels"])? "never":"always",
  headerStyle:  { whiteSpace: "nowrap" }
  }));



const labelData = translationDataList


const handleRowUpdate = async (newData, oldData, resolve) => {
  const dataUpdate = [...labelData];

  const index = oldData.tableData.id;

  dataUpdate[index] = newData;
  dataUpdate.forEach(function (v) {
    delete v.tableData;
  });
  setTranslationDataList([...dataUpdate])

  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/translation/editTranslations`,
    data: dataUpdate,
  });
  resolve();
  return response;
};
// const handleRowAdd = async(newData, resolve) => {
 
 
//         let dataToAdd = [...translationDataList];
//         dataToAdd.push(newData);
//         setTranslationDataList(dataToAdd);
//         console.log("translationDataList", translationDataList, "add", dataToAdd)
//         const response = await axios({
//           method: "post",
//           url: `${config.baseApi}dc/translation/addTranslations`,
//           data: dataToAdd
        
//         });
//      resolve();

//   return response;
// }

const handleRowDelete =async (oldData, resolve) => {

  const dataDelete = [...translationDataList];
  const index = oldData.tableData.id;
  const deletedData=dataDelete.splice(index, 1);
  setTranslationDataList(dataDelete)

  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/translation/deleteTranslations`,
    data: deletedData,
   
  });
 resolve();
 return response

}
// const handleRowBulkUpdate = async (newData, oldData, resolve) => {
//   const dataUpdate = [...labelData];
//   console.log("changes", dataUpdate, "translationDataList", newData)
//   const index = oldData.tableData.id;

//   dataUpdate[index] = newData;
//   dataUpdate.forEach(function (v) {
//     delete v.tableData;
//   });
//   setTranslationDataList([...dataUpdate])

//   // const response = await axios({
//   //   method: "post",
//   //   url: `${config.baseApi}dc/translation/editTranslations`,
//   //   data: dataUpdate,
//   // });

//  // resolve();
//   //return response;
// };

const handleRetailerUpdate = async (newData, oldData, resolve) => {
  
  let countryMapping = countryForEdit?.filter(item => item?.region === newData.countryName);
  let regionName = countryMapping.map((item)=> {return item.countryCode});
  
  console.log("codevaluecountry",regionName[0])
  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/exports/addorupdateretailer`,
    data: {
      "PK": newData.PK,
      "SK":newData.SK,
      "retailerName": newData.name,
      "retailerCode": newData.code,
      "countryCode": regionName[0],
      "emailId": newData.emailId,
      "identifier": localStorage.email
      }
  });
  resolve();
  getRetailerData()
  return response;
};

const handleRetailerDelete =async (oldData, resolve) => {

  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/exports/deleteretailer`,
    data: {
      "PK": oldData.PK,
      "SK":oldData.SK,
  }
   
  });
 resolve();
 getRetailerData()
 return response

}
// eslint-disable-next-line 
const handleRetailerAdd = async(newData, resolve) => {

 const result = newData.countryName === "Germany"
                ? "DE"
                : newData.countryName === "Brazil"
                ? "BR"
                : newData.countryName === "Spain"
                ? "ES"
                : newData.countryName === "France"
                ? "FR"
                : newData.countryName === "Netherlands"
                ? "NL"
                : newData.countryName === "US"
                ? "US"
                : newData.countryName === "Belgium"
                ? "BE"
                : newData.countryName === "Italy"
                ? "IT"
                : "Not Available"
        const response = await axios({
          method: "post",
           url: `${config.baseApi}dc/exports/addorupdateretailer`,
           data: {
            "retailerName": newData.name,   
            "countryCode": result,   
            "emailId": newData.emailId,
            "identifier": localStorage.email
            }
        
        });
     resolve();
     getRetailerData()
  return response;
}

const handleRowDeleteUser =async (deleteId) => {
  const response = await axios({
    method: "post",
    url: `${config.baseApi}dc/exports/deleteuser/${deleteUserID}`,
  });

 getUsers(getCountryCode())
 handleCloseDeleteUser()
 return response
}

function selectedRoleName() {
  const items = optionList.filter((item, index)=> {return item.SK === editRoleId});
  let val = items.map((item)=> {return item.roleName});
  // eslint-disable-next-line no-unused-expressions
  filteredOptionList = optionList.filter((item) => { return item.roleName !== "Global Admin" })
  return val[0];
}

function selectedRoleCountry() {
  const items = countryForEdit.filter((item, index)=> {return item.countryCode === editCountry});
  let val = items.map((item)=> {return item.countryCode});
  filteredCountryList = countryForEdit.filter((item) => { return item.countryCode !== "GBL" })
  return val[0];
}

const tableRef = React.createRef();
const classes = useStyles();
  return (
    <div>
      <Container style={{ paddingBottom: "10%" }}>
      <div className="title-heading">
          <span>Administration</span>
        </div>
        <div className="row">
        <TabContext value={value}>
          <div className="col-md-3 col-lg-3 col-xl-3">
              <div
                className="d-flex flex-column flex-shrink-0 p-3 bg-light"
              >
                <div className="nav nav-pills flex-column mb-auto">
                <TabList className={classes.tabs} orientation="vertical" onChange={handleChange}
                  >
                  <Tab className="nav-link-new " label= {<span className="tab" >Users</span>} value="1" />
                  <Tab className="nav-link-new"  label={<span className="tab" >Retailers</span>} value="2" />
                  <Tab className="nav-link-new"  label={<span className="tab" >Countries</span>} value="3" />
                  <Tab className="nav-link-new"   label={<span className="tab" >Analytics</span>}  value="4" />
                  <Tab className="nav-link-new"   label={<span className="tab" >Label Translation List</span>}  value="5" />
                  </TabList>
                </div>
              </div>
          </div>
          <div className="col-md-9 col-lg-9 col-xl-9">
            <TabPanel className={classes.table} value="1">
               {(gigyaRole === "Global Admin" || gigyaRole === "Country Admin") ? (
                <div>
                  {(gigyaRole === "Global Admin")?
                   <div className="col-md-9 col-lg-9 col-xl-12">
                     <div className="usercountryDropdown">
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={countryFilter}
                              label="Select Country"
                              onChange={handlecountryChange}
                              defaultValue={countryFilter}
                              displayEmpty          
                            >
                            {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
                              return (
                                 <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>{item?.region}</MenuItem>
                              );
                            })}
                            </Select>
                        </FormControl> 
             
                      </div> 
                    </div>
                    :""}
                    <MaterialTable
                        tableRef={tableRef}
                        options={options}
                        icons={tableIcons}
                        columns={columns}
                      
                        data={
                          (countryFilter=== "All Data" || countryFilter=== "" )? data :                    
                          data.filter(
                          (item) => (!countryFilter || item.country === countryFilter )
                          )}
                    />
              </div>): 
              <span style={{fontSize:"18px",fontFamily: "Neue Helvetica",color:"#00617f",fontWeight:600}}>
                Access Denied, You Don't Have Permission To View.
              </span> }
            </TabPanel>
            <TabPanel className={classes.table} value="2">
               {(gigyaRole === "Global Admin" || gigyaRole === "Country Admin") ? (
                <div>
                  {(gigyaRole === "Global Admin")?
                   <div className="col-md-9 col-lg-9 col-xl-12">
                     <div className="usercountryDropdown">
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={countryFilter}
                              label="Select Country"
                              onChange={handlecountryChange}
                              defaultValue={countryFilter}
                              displayEmpty          
                            >
                            {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
                              return (

                                <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                                  {item?.region}
                                </MenuItem>
                              );
                            })}
                            </Select>
                        </FormControl> 
             
                      </div> 
                    </div>
                    :""}
                    <MaterialTable  
                   tableRef={tableRef}
                   options={options}
                   icons={tableIcons}
                  columns={ retailerColumns}
                
                  data={
                    (countryFilter=== "All Data" || countryFilter=== "" )? retailerTable :                    
                    retailerTable.filter(
                    (item) => (!countryFilter || item.country === countryFilter )
                    )}
                  editable={ gigyaRole === "Global Admin" ? {
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                         handleRetailerUpdate(newData, oldData, resolve);
                        
                        
                  }),
                //  onRowAdd: (newData) =>
                //     new Promise((resolve) => {
                //      handleRetailerAdd(newData, resolve)
                //     }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      handleRetailerDelete(oldData, resolve)
                    }),
                    // onBulkUpdate: (newData,oldData) =>
                    // new Promise((resolve) => {
                     
                    //   handleRowBulkUpdate(newData, oldData, resolve);
                    //   // setTimeout(() => {
                    //   //   resolve();
                    //   // }, 1000);
                    // }), 
                  } : 
                  {
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                         handleRetailerUpdate(newData, oldData, resolve);
                        
                        
                  }),
                //  onRowAdd: (newData) =>
                //     new Promise((resolve) => {
                //      handleRetailerAdd(newData, resolve)
                //     }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      handleRetailerDelete(oldData, resolve)
                    }),
                    // onBulkUpdate: (newData,oldData) =>
                    // new Promise((resolve) => {
                     
                    //   handleRowBulkUpdate(newData, oldData, resolve);
                    //   // setTimeout(() => {
                    //   //   resolve();
                    //   // }, 1000);
                    // }), 
                  }}
                 
              />
              </div>): 
              <span style={{fontSize:"18px",fontFamily: "Neue Helvetica",color:"#00617f",fontWeight:600}}>
                Access Denied, You Don't Have Permission To View.
              </span> }
            </TabPanel>
            <TabPanel className={classes.table} value="3">
            {(gigyaRole === "Global Admin" || gigyaRole=== "Country Admin") ? (
              <div>
                 {(gigyaRole === "Global Admin")?
                   <div className="col-md-9 col-lg-9 col-xl-12">
                <div className="usercountryDropdown">
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={countryFilter}
                              label="Select Country"
                              onChange={handlecountryChange}
                              defaultValue={countryFilter}
                              displayEmpty          
                            >
                            {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
                              return (

                                <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                                {item?.region}
                                </MenuItem>

                              );
                            })}
                            </Select>
                        </FormControl> 
             
                      </div> 
                </div>
              :""}
           <MaterialTable
      title="Country Table"
      columns={countriesColumns}
      data={
        (countryFilter=== "All Data" || countryFilter=== "" )? countriesData :                    
        countriesData.filter(
        (item) => (!countryFilter || item?.countryCode === countryFilter )
        )}
      options= {{
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        download: false,
        checkbox: false,
        selectableRows: false,
        showTitle: false,
        addRowPosition: 'first',
        doubleHorizontalScroll: true,
        pageSize: "6",
        pageSizeOptions:["6", "12", "18"]
      }}
      
    />
               </div>):
              <span style={{fontSize:"18px",fontFamily: "Neue Helvetica",color:"#00617f",fontWeight:600}}>
                Access Denied, You Don't Have Permission To View.
              </span> }
            </TabPanel>
            <TabPanel className={classes.table} value="4">
            {(gigyaRole === "Global Admin" || gigyaRole === "Internal" || gigyaRole=== "Country Admin") ? (
              <div>
                 {(gigyaRole === "Global Admin")?
                   <div className="col-md-9 col-lg-9 col-xl-12">
                <div className="countryDropdown">
                <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={countryFilter}
              label="Select Country"
              onChange={handlecountryChange}
              defaultValue={countryFilter}
              displayEmpty

              >
              
                {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
                  return (

                    <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                    {item?.region}
                    </MenuItem>

                  );
                })}
              </Select>
          </FormControl> 
             
                </div> 
                </div>
              :""}
            <MaterialTable
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={analyticsColumns}
                  data={
                    (countryFilter=== "All Data" || countryFilter=== "" )? analyticsData :                    
                    analyticsData.filter(
                    (item) => (!countryFilter || item.CountryName === countryFilter )
                    )}
              />
               </div>):
              <span style={{fontSize:"18px",fontFamily: "Neue Helvetica",color:"#00617f",fontWeight:600}}>
                Access Denied, You Don't Have Permission To View.
              </span> }
            </TabPanel>
            <TabPanel className={classes.tableLable} value="5">
            <MaterialTable  
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={ headerTable}
                  data={labelData.map(x => Object.assign({}, x))}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        handleRowUpdate(newData, oldData, resolve);
                  }),
                //  onRowAdd: (newData) =>
                //     new Promise((resolve) => {
                //     handleRowAdd(newData, resolve)
                //     }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      handleRowDelete(oldData, resolve)
                    }),
                    // onBulkUpdate: (newData,oldData) =>
                    // new Promise((resolve) => {
                     
                    //   handleRowBulkUpdate(newData, oldData, resolve);
                    //   // setTimeout(() => {
                    //   //   resolve();
                    //   // }, 1000);
                    // }), 
                  }}
                 
              />
            </TabPanel>
          </div>
        </TabContext>
          </div>
            <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">User Name</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>

                        <Form.Control
                            type="text"
                            placeholder="Lorem ipsum"
                            onChange={(e) => {
                                setEditUserName(e.target.value)
                            }}
                            readOnly
                            value={editUserName} />

                    </Form.Group>
                </Form>

                  <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">Select Role</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                  <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                  {showModalRole !== "Global Admin"  ||  gigyaRole === "Global Admin" ? 
                    <select className="selectDropdown"
                      label="demo-simple-select-filled-label"
                      onChange={(e) => {setEditRoleId(e.target.selectedOptions[0].id)}}
                      value={selectedRoleName()}
                    >
                      {gigyaRole === "Country Admin" ?

                      filteredOptionList.map((item, index) => { 
                        return (
                          <option key={index} id={item.SK} value={item.sk} > {item.roleName}</option>
                        )
                      }) :
                      optionList.map((item, index) => { 
                        return (
                          <option key={index} id={item.SK} value={item.sk} > {item.roleName}</option>
                        )
                      })
                    }
                    </select>
                     : 
                     <Form>
                     <Form.Group className="mb-3">
 
                         <Form.Control
                             type="text"
                             placeholder="Lorem ipsum"
                            
                             readOnly
                             value="Global Admin" />
 
                     </Form.Group>
                 </Form>}
                  </Form.Group>
                </Form>
                  <Modal.Header >
                  <Modal.Title id="contained-modal-title-vcenter">Select Country</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>
                    {showModalRole !== "Global Admin"  ||  gigyaRole === "Global Admin"   ? 
                      <select className="selectDropdown"
                        label="demo-simple-select-filled-label"
                        onChange={(e) => {setEditCountry(e.target.selectedOptions[0])}}
                        value={selectedRoleCountry()}
                      >
                        {gigyaRole === "Country Admin" ?

                      filteredCountryList.map((item, index) => { 
                        return (
                          <option key={index} id={item.countryCode} value={item.countryCode} > {item.region}</option>
                        )
                      }) :
                      countryForEdit.map((item, index) => {
                        return (
                          <option key={index} id={item.countryCode} value={item.countryCode} > {item.region}</option>
                        )
                      })
                    }
                        
                      </select>
                      : <select className="selectDropdown"
                      label="demo-simple-select-filled-label"
                      disabled                   
                      onChange={(e) => {setEditCountry(e.target.selectedOptions[0])}}
                      value={selectedRoleCountry()}
                    >
                      {countryForEdit.map((item, index) => {
                        return (
                          <option key={index} id={item.countryCode} value={item.countryCode} > {item.region}</option>
                        )
                      })}
                    </select>}
                    </Form.Group>
                  </Form>
                <Modal.Footer>
                  <Button style={{ backgroundColor: "#89d329" }} onClick={() => (editModal(editRoleId, editCountry, editIndex))}>
                      Edit
                  </Button>
                  <Button variant="secondary" onClick={handleClose} >
                      Close
                  </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteUser} onHide={handleCloseDeleteUser} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>"Are you sure you want to delete the user - {deleteUserName}? This action cannot be undone."</Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: "#89d329" }} onClick={() => (handleRowDeleteUser(deleteUserID))}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleCloseDeleteUser}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showModalforCountries} onHide={handleCloseForCountries} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">Country</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>

                        <Form.Control
                            type="text"
                            placeholder="Country"
                            onChange={(e) => {
                                setEditCountryForCountries(e.target.value)
                            }}
                            readOnly
                            value={editCountryForCountries} />

                    </Form.Group>
                </Form>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">Edit Roles</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>

                        <Form.Control
                            type="text"
                            placeholder="Role"
                            onChange={(e) => {
                                setEditRoleForCountries(e.target.value)
                               
                            }}

                            readOnly ={editRoleForCountries=== "HCP" || editRoleForCountries=== "Global Admin"|| editRoleForCountries=== "Internal" || editRoleForCountries=== "Country Admin" ? true : false} 
                            value={editRoleForCountries} />

                    </Form.Group>
                </Form>
                <Modal.Body style={{ textAlign: "center", color: "black" , fontWeight:"bold"}}>
                {editRoleForCountries=== "HCP" || editRoleForCountries=== "Global Admin"|| editRoleForCountries=== "Internal" || editRoleForCountries=== "Country Admin" ? "This Role cant be edited" : ""}
                </Modal.Body>
            <Modal.Footer>
             <Button style={{ backgroundColor: "#89d329" }} onClick={handleEditRoleForCountries}>
                    Edit
                </Button>
                <Button variant="secondary" onClick={handleCloseForCountries}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    RoleSettings: state.role.countryData,
    countryCode: state.countryCode.countryCode,
    countrySettings: state.country.countryData,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateRoleSettings: ownProps => dispatch(updateRoleSettings(ownProps)),
    updateRenderCountryCode: ownProps => dispatch(updateRenderCountryCode(ownProps))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Administration);
