import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import {
  downloadOnClick,
  getCountryCode,
  getProductTitle
} from "../../util/Helper.js";
import { updateFavProductsList, updateFavAssetsList } from "../../redux";
//import qs from "qs";
import _ from "lodash";
import axios from "axios";
import config from "../../config.js";
import "./cardDetail.scss";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
//import AuthContext from "../../components/Auth/Auth-context";
// import { Button } from "react-bootstrap";
var dayjs = require("dayjs");

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
let downloadArray=[]
window.dataLayer = window.dataLayer || [];
const countryCode = getCountryCode();
const Card = forwardRef((props) => {

  const classes = useStyles();
  let { item, index, match, productsList,downloadAllRef,downloadAllCheckBox, sendingProps,triggerFlag, RoleSettings, countryLocale} = { ...props };
  const { t } = props;

  let [enableDownload, setEnableDownload] = useState(true);
  let [backDropOpen, setBackDrop] = useState(false);
  let [license_expiry_date, setExpiryDate] = useState(null);
  let [release_date, setReleaseDate] = useState(null);
  let [remaining_days, setRemainingDays] = useState(null);
  // let [chkbox, setchkbox] = useState(false);
  // let [chkboxAsset, setchkboxAsset] = useState(false);
  let [download, setdownload] = useState(false);
    // eslint-disable-next-line
  const [gigyaRole, setGigyaRole] = useState();
  //const AuthCtx = useContext(AuthContext);
  //const userId = AuthCtx.giveToken();
    // eslint-disable-next-line
  const userId= localStorage.email
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
      data: {
        "useAdministration": {
          "Gigya_ID": userId,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response.data.data?.roleName);
  }

  useEffect(() => {
    if (item.type === "asset") {
      let set,
        exp_date = null;
      set = Object.keys(item).includes("license_expiry_date") && item.license_expiry_date.length > 0 ? false : true;
      setEnableDownload(set);
      !set && setExpiryDate(item.license_expiry_date);
      let today = dayjs();
      if (!set) {
        exp_date = dayjs(item.license_expiry_date);
      }

      if (exp_date != null && exp_date.diff(today, "day") < 31) {
        setRemainingDays(exp_date.diff(today, "day"));
      }
    }
    if (item.type === "product") {
      let set;
      if (Object.keys(item).includes("asset_info")) {
        set = Object.keys(item.asset_info).includes("license_expiry_date")
          ? false
          : true;
        !set && setExpiryDate(item.asset_info.license_expiry_date);
      } else if (Object.keys(item).includes("license_expiry_date")) {
        set = true;
        setExpiryDate(item.license_expiry_date);
      } else {
        set = false;
      }
      setEnableDownload(set);
    }
    document.addEventListener("click", e => {
      if (e.target.classList.contains("MuiBackdrop-root")) {
        setBackDrop(false);
      }
    });
  }, [item]);
  useEffect(() => {
    if (item.type === "asset") {
      let set,
        exp_date = null;
      set = Object.keys(item).includes("release_date") && item.release_date.length > 0 ? false : true;
      setEnableDownload(set);
      !set && setReleaseDate(item.release_date);
      
      let today = dayjs();
      if (!set) {
        exp_date = dayjs(item.release_date);
      }

      if (exp_date != null && exp_date.diff(today, "day") < 31) {
        setReleaseDate(exp_date.diff(today, "day"));
      }
      if(countryCode === "de") {
        const [year, month, day] =  item.release_date.split('-')

        // dd/mm/yyyy
       let formatedDate= `${day}-${month}-${year}`
       setReleaseDate(formatedDate);
      }
      else{
        setReleaseDate(item.release_date);
      }
        
      
    }
    if (item.type === "product") {
      let set;
      if (Object.keys(item).includes("asset_info")) {
        set = Object.keys(item.asset_info).includes("release_date")
          ? false
          : true;
        !set && setReleaseDate(item.asset_info.release_date);
      } else if (Object.keys(item).includes("release_date")) {
        set = true;
        setReleaseDate(item.release_date);
      } else {
        set = false;
      }
      setEnableDownload(set);
    }
    if(countryCode === "de") {
      const [year, month, day] =  item?.release_date && item.release_date.split('-')

      // dd/mm/yyyy
     let formatedDate= `${day}-${month}-${year}`
     formatedDate && setReleaseDate(formatedDate);
    }
    else{
      item.release_date && setReleaseDate(item.release_date);
    }
    document.addEventListener("click", e => {
      if (e.target.classList.contains("MuiBackdrop-root")) {
        setBackDrop(false);
      }
    });
  }, [item]);


  useEffect(()=>{
    sendingProps(downloadArray.length)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[downloadArray.length,downloadAllCheckBox,triggerFlag])
  useEffect(() => {
    postGigyaID();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    setdownload(downloadAllCheckBox);
    if (downloadAllCheckBox) {
      if (item.type === "excel") {
        if (downloadArray.findIndex((ele) => ele.type === "excel" && ele.fileName !== item.fileName) !== -1) {
          downloadArray = [];
          downloadArray.push(item);
        } else if (downloadArray.findIndex((ele) => ele.type === "excel" && ele.fileName === item.fileName) === -1) {
          downloadArray.push(item);
        }
      } else if (item.type === "packageInsertLink") {
        if (downloadArray.findIndex((ele) => ele.type === "packageInsertLink"&& ele.fileName !== item.fileName) !== -1) {
          downloadArray = [];
          downloadArray.push(item);
        } else if (downloadArray.findIndex((ele) => ele.type === "packageInsertLink" && ele.fileName === item.fileName) === -1) {
          downloadArray.push(item);
        }
      } else {
        if(downloadArray.findIndex((ele) => (ele.type !=="excel" && ele.upc===item.upc) || (ele.type ==="excel")) === -1 ) { 
          downloadArray=[]      
          downloadArray.push(item);
        }  
        else if(downloadArray.findIndex((ele) =>ele.type !=="excel" &&  ele.upc=== item.upc &&  ele.id === item.id ) === -1) {
          downloadArray.push(item);
        }else  if(downloadArray.findIndex((ele) => (ele.type !=="packageInsertLink" && ele.upc===item.upc) || (ele.type ==="packageInsertLink")) === -1 ) { 
          downloadArray=[]      
          downloadArray.push(item);
        }  
        else if(downloadArray.findIndex((ele) =>ele.type !=="packageInsertLink" &&  ele.upc=== item.upc &&  ele.id === item.id ) === -1) {
          downloadArray.push(item);
        }  
      }
    } else {
      if (item.type === "excel") {
        downloadArray = downloadArray?.filter(ele => ele.type !== "excel");
      } else if (item.type === "packageInsertLink") {
        downloadArray = downloadArray?.filter(ele => ele.type !== "packageInsertLink");
      } else {
        downloadArray = downloadArray?.filter(ele => ele.id !== item.id);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAllCheckBox, triggerFlag]);
  
  
  const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtensionXlsx = ".xlsx";

  const exportToCSV = (event, apiData, fileName, brand) => {
    event?.currentTarget.classList.add("done");
    event?.currentTarget.lastElementChild.classList.remove(
      "bi-file-earmark-arrow-down"
    );
    event?.currentTarget.lastElementChild.classList.add("bi-check-lg");
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtensionXlsx);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Download (local)",
      eventAction: brand,
      eventLabel: "product",
      eventValue: "ProductDataPackage.xlsx",
      environment: ga_env
    });
   
  };

   const getPackageInsert = item => {
      window.open(item, '_blank', 'noopener,noreferrer');
};

  const placeholder = item => {
    if (item.type === "product") {
      let imageUrl,
        preview_available = false;
        const domain = window.location.origin;
      if (
      Object.keys(item).includes("asset_info_print") &&
      Object.keys(item.asset_info_print).includes("preview_location")
      ) {
      imageUrl = `${domain}/${item.asset_info_print.preview_location}`;
      preview_available = true;
      }else if (Object.keys(item).includes("thumbnail")) {
        imageUrl = item.thumbnail;
        if (imageUrl !== undefined && imageUrl.length > 0) {
          preview_available = true;
        }
      } else if (
        Object.keys(item).includes("asset_info") &&
        Object.keys(item.asset_info).includes("preview_location")
      ) {
        imageUrl = `${domain}/${item.asset_info.preview_location}`;
        preview_available = true;
      } else if (Object.keys(item).includes("asset_location")) {
        let primaryImage = "";
        if (Array.isArray(item.asset_location)) {
          primaryImage = item.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
        } else if (
          item.asset_location.includes("Primary_Image") &&
          item.asset_location.indexOf("_MACOSX") === -1
        ) {
          primaryImage = item.asset_location;
        }
        if (primaryImage.length > 0) {
          imageUrl = `${domain}/${primaryImage}`;
          preview_available = true;
        }
      }
      if (preview_available) {
        return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
      } 
      else if(item.type ==="excel"){
        return (
          <React.Fragment>
            <div className="no-preview">
           <div style={{fontSize:"4.5vh", color:"#66b512"}}><FontAwesomeIcon icon={faFileExcel} /></div> 
            </div>
          </React.Fragment>
        );
      }
      else {
        return (
          <React.Fragment>
            <div className="no-preview">
              <div className="bayer-logo-backgroundnew"></div>
              {( gigyaRole === "Internal") ?
              <span className="textnew" style={{fontSize: "4px"}}>{t("Miscellaneous.noPreviewForInternal")}</span>
              :
              <span className="textnew">{t("Miscellaneous.noPreview")}</span>}
            </div>
          </React.Fragment>
        );
      }
    } else {
      let isPreviewAvailable = false;
      let previewFileSrc;
      let imageUrl;
      const domain = window.location.origin;
      
      if (_.has(item, "preview_location")) {
        previewFileSrc = item.preview_location;
        imageUrl = `${domain}/${previewFileSrc}`;
        isPreviewAvailable = true;
      } else if (_.has(item, "asset_location")) {
        if (Array.isArray(item.asset_location)) {
          previewFileSrc = item.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
          if (previewFileSrc.length > 0) {
            isPreviewAvailable = true;
            imageUrl = `${domain}/${previewFileSrc}`;
          } else {
            const imageTypes = ["png", "jpg", "jpeg"];
            previewFileSrc = item.asset_location.filter(file => {
              const check = imageTypes.some(imgtype => file.includes(imgtype));
              return check;
            });
            if (previewFileSrc.length === 1) {
              isPreviewAvailable = true;
            }
          }
        } else {
          previewFileSrc = item.asset_location;
          const extension = previewFileSrc.slice(
            ((previewFileSrc.lastIndexOf(".") - 1) >>> 0) + 2
          );
          const imageTypes = ["png", "jpg", "jpeg"];
          if (imageTypes.includes(extension)) {
            isPreviewAvailable = true;
            imageUrl = `${domain}/${previewFileSrc}`;
          }
        }
      }
      if (isPreviewAvailable) {
        imageUrl = `${domain}/${previewFileSrc}`;
        return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
      } 
      else if(item.type ==="excel"){
        return (
          <React.Fragment>
            <div className="no-preview">
           <div style={{fontSize:"4.5vh", color:"#66b512"}}><FontAwesomeIcon icon={faFileExcel} /></div> 
              {/* <span className="textnew">{t("Miscellaneous.noPreview")}</span> */}
            </div>
          </React.Fragment>
        );
      }
     
      else {
        return (
          <React.Fragment>
            <div className="no-preview">
              <div className="bayer-logo-backgroundnew"></div>
              {( gigyaRole === "Internal") ?
              <span className="textnew" style={{fontSize: "4px"}} >{t("Miscellaneous.noPreviewForInternal")}</span>
              :
              <span className="textnew">{t("Miscellaneous.noPreview")}</span>}
            </div>
          </React.Fragment>
        );
      }
    }
  };

  const handleDownloadClick = (e, entity, type) => {
    e.preventDefault();
    // if (enableDownload) {
      
      downloadOnClick(e, entity, type);    
    // } else {
    //   setBackDrop(true);
    // }
  };
 

  const downloadReturnFunc =(entity)=>{
    if(entity.type === "excel" ){
      return(
        <button
         className="downloadButton"
         onClick={e => exportToCSV(e, entity.apiData, entity.fileName, entity.brand)}
       >
         
         <i className="bi bi-download downloadicon"></i>
         {/* <i className="bi bi-file-earmark-arrow-down"></i> */}
       </button>
      )
         
    }
    else if(entity.type === "packageInsertLink" ){
      return(
        <button
        className="downloadButton"
        onClick={()=>getPackageInsert(entity.filePath)}
      >
        
        <i className="bi bi-download downloadicon"></i>
        {/* <i className="bi bi-file-earmark-arrow-down"></i> */}
      </button>
      )
    }
    else if(entity.type!=="excel" ||  entity.type !== "packageInsertLink"){
      return(
        <button
      className="downloadButton"
      onClick={e => handleDownloadClick(e, entity, "asset")}
      data-brand={item.brand}
      >                                                      
        <i className="bi bi-download downloadicon"></i>                                                         
      </button>
      )
    }

     
  }

  const downloadButton = entity => {


    if (Object.keys(item).includes("asset_location")) {
      return (
        downloadReturnFunc(entity)
         
      );
    }
  };

  const addFavorites = event => {
    event.preventDefault();  
    let favSet = event.currentTarget.getAttribute("data-favset");
    const type = event.currentTarget.getAttribute("data-type");
    const id = event.currentTarget.getAttribute("data-id");
    const title = event.currentTarget.getAttribute("data-title");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = props.favProductsList;
    let assetidList = props.favAssetsList;
    favouriteButton("")
    dispCardAssetBrand("")
    displayExpiryMessage("")
    if (favSet === "false") {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark-fill");
      switch (type) {
         case "product":
          if (productidList !== undefined) {
            let dataList={
              "notificationFlag": "",
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": ""
          }
            productidList.push(dataList);
            props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": ""
          // }
          //   assetidList.push(dataList);
            assetidList.push(id);
            props.updateFavAssetsList(assetidList);
          }       
          break;
        default:
          break;
      }     
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark-fill");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            let dataList={
              "notificationFlag": "",
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": ""
          }
          //productidList = productidList.filter(productId => productId !== id);

            productidList = productidList.filter(productId => productId.productId !== dataList.productId  );

              // if(productidList?.[0].productId  !== dataList?.productId ){
              //   console.log("productidList",productidList?.[0].productId, dataList.productId )
              //   productidList.push(dataList);
              // }
              //productidList.push(dataList);
              //console.log("productidList123",productidList?.[0].productId, dataList.productId )

          
            props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn":currentDate,
          //     "notificationCheckedOn": ""
          // }
          // assetidList = assetidList.filter(assetId => assetId.assetId !== dataList.assetId);
          assetidList = assetidList.filter(assetId => assetId !== id);
          props.updateFavAssetsList(assetidList);
          }
          break;
        default:
          break;
      }     
    }

    if (type === "product") {
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/favorites`,
        data: {
          favorites: {
            products: productidList
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
    if (type === "asset") {
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/favorites`,
        data: {
          favorites: {
            assets: assetidList
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: title,
      eventLabel: type,
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  const favouriteButton = item => {
    let favSet=false;
    let favProducts = props.favProductsList;
    let favAssets = props.favAssetsList;
    let filteredProductList;
    //let filteredAssettList;
    let itemTitle;   
    switch (item.type) {
      case "product":
        if (favProducts !== undefined) {
          filteredProductList= favProducts.map((prodId)=>prodId.productId)
          favSet = filteredProductList.includes(item.id) ? true : false;
        }
        itemTitle = item.ptitle;
        break;
      case "asset":
        if (favAssets !== undefined) {
          // filteredAssettList= favAssets.map((AssetId)=>AssetId.assetId)
          // favSet = filteredAssettList.includes(item.id) ? true : false;
          favSet = favAssets.includes(item.id) ? true : false;
        }
        itemTitle = item.title;
        break;
      default:
        break;
    }   
    let icon = favSet === true ? "bi-bookmark-fill" : "bi-bookmark";
    return (
      <div
        className="fav-icon"
        data-type={item.type}
        data-id={item.id}
        data-favset={favSet}
        data-title={itemTitle}
        onClick={e => addFavorites(e)}
      >
      {RoleSettings &&  RoleSettings['Mark favourites'] === true?   <i className={`bi ${icon} downloadicon`}></i> : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
      </div>
    );
  };

  const dispCardAssetBrand = brand => {
    let brandString = "N/A";
    if (brand !== undefined && brand.length > 0) {
      brandString = brand.map(item => `${item}`).join("/");
      return brandString;
    }
    return brandString;
  };

  const handleClickOnCard = item => {
    let { url } = { ...match };
    url =
      url.charAt(url.length - 1) === "/"
        ? url.substring(0, url.length - 1) + "" + url.substring(url.length)
        : url;
    let paramObj;
    switch (item.type) {
      case "product":
        paramObj = {
          pathname: `${url}/product-detail-page/${item.id}`,
          state: {
            queryParams: { brand: [item.brand.trim()] },
            id: item.id,
            title: item.ptitle
          }
        };
        break;
      case "asset":
        let flag;
        flag = url.includes("/asset-detail-page")
          ? 0
          : url.includes("/product-detail-page")
            ? 1
            : null;
        switch (flag) {
          case 0:
            paramObj = {
              pathname: `${url.substring(0, url.lastIndexOf("/"))}/${item.id}`,
              state: {
                queryParams: { brand: [item.brand.trim()] },
                id: item.id,
                title: dynamicAssetTitle(item)
              }
            };
            break;
          case 1:
            paramObj = {
              pathname: `${url
                .substring(0, url.lastIndexOf("/"))
                .replace("product-detail-page", "asset-detail-page")}/${item.id
                }`,
              state: {
                queryParams: { brand: [item.brand.trim()] },
                id: item.id,
                title: dynamicAssetTitle(item)
              }
            };
            break;
          default:
            paramObj = {
              pathname: `${url}/asset-detail-page/${item.id}`,
              state: {
                queryParams: { brand: [item.brand.trim()] },
                id: item.id,
                title: dynamicAssetTitle(item)
              }
            };
            break;
        }
        break;
      default:
        break;
    }
    return paramObj;
  };

  const fileExtension = asset => {
    const file_type =
      Object.keys(asset).includes("file_type") && asset.file_type !== ""
        ? asset.file_type
        : "N/A";
    return file_type;
  };

  const dynamicAssetTitle = item => {
    let asset_title = [];
    if (Object.keys(item).includes("upc")) {
      let ptitle = getProductTitle(item.upc, productsList, countryLocale);
      ptitle !== undefined
        ? asset_title.push(ptitle)
        : asset_title.push(item.title);

      if (_.has(item, "asset_type")) {
        asset_title.push(t(`Asset Type.${item.asset_type[0].dcAssetType}`));
      }
    } else {
      if (_.has(item, "brand")) {
        let assetBrand = item.brand;
        if (_.has(item, "sub_brand")) {
          asset_title.push(item.sub_brand[0]);
        } else {
          asset_title.push(assetBrand);
        }
      }
      if (_.has(item, "asset_type")) {
        asset_title.push(t(`Asset Typee.${item.asset_type[0].dcAssetType}`));
      }
      if (_.has(item, "customer_explanation")) {
        asset_title.push(item.customer_explanation);
      }
    }
    asset_title = asset_title.join(" ");
    return asset_title;
  };

  const disclaimerCheckbox = () => {
    return (
      <div className="disclaimer-text-container">
        <div className="disclaimer-checkbox">
          <input
            type="checkbox"
            onClick={() => {
              setEnableDownload(!enableDownload);
              //setBackDrop(!backDropOpen);
            }}
          ></input>
        </div>
        <div className="disclaimer-text">
          {t("Miscellaneous.license-expiry-text")} {license_expiry_date}

        </div>
      </div>
    );
  };

  const displayExpiryMessage = days => {
    if (days != null) {
      return (
        <div className="asset-expiry">
            {/* <span style={{color:"#ff3162"}}>{`${t("Miscellaneous.card_expiry_message")}:`}</span> */}
          <span >{`${t('Miscellaneous.card_expiry_message',{exDate:remaining_days})}`}</span>
          {/* <span>{remaining_days}</span> */}
        </div>
      );
    }
  };
 
  useImperativeHandle(downloadAllRef, () => ({
    downloadAll() {
      downloadAll()
    }
  }))
  const downloadAll=()=>{
    if(downloadArray.length>0){
     
      downloadArray.map((item,index)=>( item.type ==="excel"?  exportToCSV(null, item.apiData, item.fileName, item.brand) 
      : item.type ==="packageInsertLink" ? getPackageInsert(item.filePath)
       : downloadOnClick(null, item,"asset")  )) 
    }
    else{
      console.log("no element")
    }
  }
 
  const OnCheckBoxChange = (e, item) => {
    setdownload(!download);
    if (e.target.checked) {
      if (item.type === "excel") {
        if (downloadArray.findIndex((ele) => ele.type === "excel" && ele.fileName !== item.fileName) !== -1) {
          downloadArray = [];
          downloadArray.push(item);
        } else if (downloadArray.findIndex((ele) => ele.type === "excel" && ele.fileName === item.fileName) === -1) {
          downloadArray.push(item);
        }
      } else if (item.type === "packageInsertLink") {
        if (downloadArray.findIndex((ele) => ele.type === "packageInsertLink"&& ele.fileName !== item.fileName) !== -1) {
          downloadArray = [];
          downloadArray.push(item);
        } else if (downloadArray.findIndex((ele) => ele.type === "packageInsertLink" && ele.fileName === item.fileName) === -1) {
          downloadArray.push(item);
        }
      } else {
        if(downloadArray.findIndex((ele) => (ele.type !=="excel" && ele.upc===item.upc) || (ele.type ==="excel")) === -1 ) { 
          downloadArray=[]      
          downloadArray.push(item);
        }  
        else if(downloadArray.findIndex((ele) =>ele.type !=="excel" &&  ele.upc=== item.upc &&  ele.id === item.id ) === -1) {
          downloadArray.push(item);
        }else  if(downloadArray.findIndex((ele) => (ele.type !=="packageInsertLink" && ele.upc===item.upc) || (ele.type ==="packageInsertLink")) === -1 ) { 
          downloadArray=[]      
          downloadArray.push(item);
        }  
        else if(downloadArray.findIndex((ele) =>ele.type !=="packageInsertLink" &&  ele.upc=== item.upc &&  ele.id === item.id ) === -1) {
          downloadArray.push(item);
        }  
      }
    } else {
      if (item.type === "excel") {
        downloadArray = downloadArray?.filter(ele => ele.type !== "excel");
      } else if (item.type === "packageInsertLink") {
        downloadArray = downloadArray?.filter(ele => ele.type !== "packageInsertLink");
      } else {
        downloadArray = downloadArray?.filter(ele => ele.id !== item.id);
      }
    }
  };
  
 function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

  let presentationComp;
 

  if (item?.type === "product") {
    presentationComp = (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop>
        <div className="card-blocknew">
          <table key={index} className="card-compnew"> 

            <tr className="top-sectionnew">
              <td><input type="checkbox" id={item.id} onChange={(e) => OnCheckBoxChange(e,item) } checked={download}></input></td>
              <td className="thumbnailnew"><Link className="card-block" to={handleClickOnCard(item)}>{placeholder(item)}</Link></td> 
              <td className="productmarkernew"><Link className="card-block" to={handleClickOnCard(item)}>{t("Product Detail Page.cardmarker")}</Link></td>
              <td className="disclaimer-text">{release_date}</td>  
              <td className="file-size">{item.file_size}</td> 
              <td className="file-extension">{fileExtension(item)}</td>
              <td className="favouriteButton">{favouriteButton(item)}
              {RoleSettings &&  RoleSettings['Download Content'] === true?
              <div className="downloadButton">{downloadButton(item)}</div>:"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
              </td>
                           
            </tr>                    
          </table>
        </div>
      </React.Fragment>
    );
  }
  if (item?.type === "asset") {
    presentationComp = (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop>
        <div className="card-blocknew">
          <table key={index} className="card-compnew">
        
            <tr className="top-sectionnew">   
              <td><input type="checkbox" id={item.id}  onChange={(e) =>  OnCheckBoxChange(e,item)}  checked={download}></input></td>        
              <td className="thumbnailnew"><Link to={handleClickOnCard(item)}> {placeholder(item)}</Link></td> 
              <td className="assetmarkernew"><Link style={{ color: '#00617f',  textDecoration : 'none'}}  to={handleClickOnCard(item)}> { !endsWith(item?.asset_location, "Print.zip") ? t(`Asset Type Card.${item.asset_type[0].dcAssetType}`) : t("Asset Detail Page.PrintProduct")}</Link></td>
              <td className="disclaimer-text">{release_date}</td>                               
              <td className="file-size">{item.file_size}</td>  
              <td className="file-extension">{fileExtension(item)} 
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              <div className="favouriteButton">{favouriteButton(item)}</div>
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              {RoleSettings &&  RoleSettings['Download Content'] === true?
              <div className="downloadButton">{downloadButton(item)}</div> :"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
              </td>
            </tr>                     
          </table>   
 
        </div>
      </React.Fragment>
    );
  }
  if (item?.type === "excel") {
    presentationComp = (
      <React.Fragment>
        {/* <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop> */}
        <div className="card-blocknew">
          <table key={index} className="card-compnew">
        
            <tr className="top-sectionnew">   
              <td><input type="checkbox" id={item.id}  onChange={(e) =>  OnCheckBoxChange(e,item)}  checked={download}></input></td>             
              <td className="thumbnailnew">{placeholder(item)}</td> 
              <td className="assetmarkernew" style={{ color: '#00617f'}}>{t("Asset Detail Page.Excel")}</td>
              <td className="disclaimer-text">{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</td>                               
              <td className="file-size">{"25 KB"}</td>  
              <td className="file-extension">{"xlsx"}   
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              <div className="favouriteButton" style={{visibility: "hidden"}}>{favouriteButton(item)}</div>
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              {RoleSettings &&  RoleSettings['Download Content'] === true?
              <div className="downloadButton">{downloadButton(item)}</div> :"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
             
              </td>   
              {/* <td className="downloadButton">{downloadButton(item)}</td>        */}
            </tr>                     
          </table>   
 
        </div>
      </React.Fragment>
    );
  }
  if (item?.type === "packageInsertLink") {
    presentationComp = (
      <React.Fragment>
        {/* <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop> */}
        <div className="card-blocknew">
          <table key={index} className="card-compnew">
        
            <tr className="top-sectionnew">   
              <td><input type="checkbox" id={item.id}  onChange={(e) =>  OnCheckBoxChange(e,item)}  checked={download}></input></td>             
              <td className="thumbnailnew">{placeholder(item)}</td> 
              <td className="assetmarkernew" style={{ color: '#00617f'}}>Package Insert</td>
              <td className="disclaimer-text">{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</td>                               
              <td className="file-size">NA</td>  
              <td className="file-extension">{item.fileType}   
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              <div className="favouriteButton" style={{visibility: "hidden"}}>{favouriteButton(item)}</div>
              <div>{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}</div>
              {RoleSettings &&  RoleSettings['Download Content'] === true?
              <div className="downloadButton">{downloadButton(item)}</div> :"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}
             
              </td>   
              {/* <td className="downloadButton">{downloadButton(item)}</td>        */}
            </tr>                     
          </table>   
 
        </div>
      </React.Fragment>
    );
  }
  return presentationComp;
});
const CardComponentNew = withTranslation("common")(Card);
const mapStateToProps = state => {
  return {
    productsList: state.product.productsList,
    renderProductList: state.product.renderProductList,
    assetsList: state.asset.assetsList,
    renderAssetList: state.asset.renderAssetList,
    filterRem: state.filter.filterRem,
    toggleData: state.filter.toggleData,
    favAssetsList: state.asset.favAssetsList,
    favProductsList: state.product.favProductsList,
    countrySettings: state.country.countryData,
    RoleSettings: state.role.countryData,
    countryLocale: state.locale.countryLocale
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFavProductsList: ownProps =>
      dispatch(updateFavProductsList(ownProps)),
    updateFavAssetsList: ownProps => dispatch(updateFavAssetsList(ownProps))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CardComponentNew);