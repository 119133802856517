import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
// import FormControl from "@material-ui/core/FormControl";
import FormControl from "@mui/material/FormControl";
import { InputLabel,Select} from "@mui/material"
import { MenuItem } from "@material-ui/core";
import { Container } from "react-bootstrap";
import "./LocaleDropdown.scss";
import store from "../../redux/store";
import {fetchLocaleSuccess,updateRenderLocaleList} from "../../redux";
import axios from "axios";
import config from "../../config.js";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const LocaleDropdownPage = (props) => { 
    const { t } = useTranslation("common");
    const {countrySettings, countryCode} = { ...props };
    const [locale, setLocale] = useState()
    const userId = localStorage.email;

    const postDefault = async () => {
      const defaultLangres = await axios({
        method: "get",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
      });
      if(defaultLangres && defaultLangres.data.data.settings){
        if (defaultLangres?.data?.data?.settings?.hasOwnProperty("defaultLocale")) {
          setLocale(defaultLangres?.data?.data?.settings?.defaultLocale)
        }
       
      } 
    }

    const handleLocaleChange = async(evt) => {
        setLocale(evt.target.value);
        store.dispatch(fetchLocaleSuccess(evt.target.value ? evt.target.value : locale? locale : "en-ZZ"));
        props.updateRenderLocaleList(evt.target.value ? evt.target.value :locale ? locale : "en-ZZ");
        const defaultLangres = await axios({
          method: "get",
          url: `${config.favApi}dc/userdata/${userId}/settings`,
        });
        let defaultLangnew = defaultLangres?.data?.data?.settings?.defaultLang
        const response = await axios({
          method: "POST",
          url: `${config.favApi}dc/userdata/${userId}/settings`,
          data: {
            settings: {
              defaultLang: defaultLangnew,
              defaultLocale : evt.target.value ? evt.target.value :locale ? locale : "en-ZZ"
            }
          },
          headers: {
            "X-Country-Code": `${countryCode}`
          }
        })
        return response
      }
      localStorage.setItem("localeLang", locale)
      props.updateRenderLocaleList(locale);

      useEffect(() => {
        postDefault()
          //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [localStorage.getItem("checkUser"), countryCode]); 
     
      let filterLanguage = countrySettings?.languages &&  countrySettings?.languages.filter(item => item.countryWithLangCode !== "en-ZZ");
      return (
  <>
      {filterLanguage?.length > 1 ? (
 <Container className="footer-page">
 <div className="localeDropdown">
   <FormControl variant="outlined">
     <InputLabel id="demo-simple-select-label">{t("HomePage.selectLocale")}</InputLabel>
     
         <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={filterLanguage?.some(setting => setting?.countryWithLangCode === locale) ? locale : "en-ZZ"}
           label={t("HomePage.selectLocale")}
           onChange={handleLocaleChange}
           defaultValue={filterLanguage?.some(setting => setting?.countryWithLangCode === locale) ? locale : "en-ZZ"}
           displayEmpty
         >
           { 
             filterLanguage?.map((item, index) => (
               <MenuItem key={index} value={item?.countryWithLangCode}>
                 {item?.lang}
               </MenuItem>
             ))
           }
         </Select>
   </FormControl>
 </div>
</Container>
) : (
"" 
)}
</>
)
}

const LocaleDropdown = withTranslation("common")(LocaleDropdownPage);

const mapStateToProps = state => ({
    countrySettings: state.country.countryData,
    countryCode: state.countryCode.countryCode
  });

  const mapDispatchToProps = dispatch => {
    return {
      updateRenderLocaleList: ownProps =>
        dispatch(updateRenderLocaleList(ownProps)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(LocaleDropdown);

